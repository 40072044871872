import { iconModalInfo } from '../../assets/icons'
import DefaultModal from '../../components/DefaultModal'
import useFormatText from '../../hooks/useFormatText'
import { sendMessageToParent } from '../Main/constants'

const ErrorModal = ({ onClose }: { onClose: () => void }) => {
  const onCloseErrorModal = () => {
    sendMessageToParent({ code: 'ROUTE', data: '/products' })
  }

  return (
    <DefaultModal
      icon={iconModalInfo}
      title={useFormatText('AI_ERROR_MODAL_TITLE')}
      content={useFormatText('AI_ERROR_MODAL_DESCRIPTION')}
      confirmBtnText={useFormatText('AI_ERROR_MODAL_OK_BUTTON')}
      cancleBtnText={useFormatText('AI_ERROR_MODAL_CANCEL_BUTTON')}
      closable={false}
      onClose={onCloseErrorModal}
      onConfirm={onClose}
    />
  )
}

export default ErrorModal
