import { useEffect, useRef, useState } from 'react'
import { Scenario } from '../../../types/project'
import { addMissingText } from '../constants'
import { useAiStore } from '../../../stores/ai'

const useScenarioTyping = ({
  scenario,
  isComplete,
  isReset,
  handleStartTyping
}: {
  scenario?: Scenario | null
  isComplete: boolean
  isReset: boolean
  handleStartTyping?: () => void
}) => {
  const [copyAppealPoint, setCopyAppealPoint] = useState('')
  const appealPointRef = useRef('')
  const timerRef = useRef<NodeJS.Timeout | null>(null)
  const { aiInfos } = useAiStore()
  const usedPoint = aiInfos?.usedPoint

  const handleText = () => {
    if (!appealPointRef.current) return
    setCopyAppealPoint((prev) => {
      const newText = addMissingText(appealPointRef.current || '', prev || '')
      return newText
    })
  }

  useEffect(() => {
    if (
      usedPoint &&
      (scenario?.[`appealPoint${usedPoint}`]?.text || []).length > 0 &&
      !timerRef.current
    ) {
      timerRef.current = setInterval(() => {
        handleText()
      }, 100)
      handleStartTyping && handleStartTyping()
    }
  }, [scenario, usedPoint])

  useEffect(() => {
    if (scenario && usedPoint) {
      appealPointRef.current =
        scenario?.[`appealPoint${usedPoint}`]?.text.join('\n')
    }
  }, [scenario, usedPoint])

  useEffect(() => {
    if (
      isComplete &&
      usedPoint &&
      scenario?.[`appealPoint${usedPoint}`]?.text.join('\n') ===
        copyAppealPoint &&
      timerRef.current
    ) {
      clearInterval(timerRef.current)
    }
  }, [copyAppealPoint, isComplete, usedPoint])

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current)
      }
    }
  }, [])

  useEffect(() => {
    if (isReset) {
      setCopyAppealPoint('')
    }
  }, [isReset])

  return {
    copyAppealPoint
  }
}

export default useScenarioTyping
