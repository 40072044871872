import Axios from 'axios'
import { redirectVplateLoginPage } from '../vplate'
import Cookie from 'js-cookie'
import { USER_INFO } from '../../constants/cookie'
const { create } = Axios

export default function () {
  const userInfoCookie = Cookie.get(USER_INFO)
  const userInfo = userInfoCookie ? JSON.parse(userInfoCookie) : {}
  const accessToken = userInfo?.token?.accessToken

  const headers = {
    'Access-Control-Allow-Origin': '*'
  }

  const axios = create({
    baseURL: `${process.env.REACT_APP_API_URL}/plugin`,
    withCredentials: false,
    headers
  })
  axios.defaults.headers = headers

  axios.interceptors.request.use(async (config) => {
    let token
    if (!accessToken) {
      const newUserInfoCookie = Cookie.get(USER_INFO)
      const newUserInfo = newUserInfoCookie ? JSON.parse(newUserInfoCookie) : {}
      token = newUserInfo?.token?.accessToken
      if (token) {
        config.headers.Authorization = `Bearer ${token || ''}`
      }
    }
    config.headers.Authorization = `Bearer ${accessToken || ''}`
    const countryCode = (localStorage.getItem('countryCode') || 'US')?.replace(
      /"/g,
      ''
    )
    const locale = (localStorage.getItem('locale') || 'en')?.replace(/"/g, '')
    const temp = (config.url || '').includes('?') ? '&' : '?'
    config.url = `${
      config.url
    }${temp}lang=${locale}&country=${countryCode.replace(/"/g, '')}`
    return config
  })

  axios.interceptors.response.use((response) => {
    if (response?.data?.code === 'AUTH_ERROR') {
      Cookie.remove(USER_INFO)
      redirectVplateLoginPage()
    }
    return response
  })

  return axios
}
