import { useState } from 'react'

import { iconModalClose } from '../../assets/icons'
import {
  CloseBtn,
  ModalBackground,
  ModalButtonContainer,
  ModalContent,
  ModalIcon,
  ModalTitle,
  ModalWrapper,
  StyledButton
} from './styles'
import DefaultButton from '../DefaultButton'

interface ModalProps {
  icon: string
  title: string
  content?: string
  cancleBtnText?: string
  confirmBtnText: string
  onClose: () => void
  onConfirm: () => void
  closable?: boolean
  confirmButtonProps?: any
}

const DefaultModal = ({
  icon,
  title,
  content,
  cancleBtnText,
  confirmBtnText,
  onClose,
  onConfirm,
  closable = true,
  confirmButtonProps
}: ModalProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(true)

  const handleCloseBtnClick = () => {
    onClose()
    setIsOpen(false)
  }

  const handleConfirm = () => {
    onConfirm()
  }

  return (
    <>
      {isOpen && (
        <ModalBackground onClick={() => closable && handleCloseBtnClick}>
          <ModalWrapper
            hasCancelButton={!!cancleBtnText}
            onClick={(e) => e.stopPropagation()}
          >
            {closable && (
              <CloseBtn onClick={handleCloseBtnClick} icon={iconModalClose} />
            )}
            <ModalIcon icon={icon}>
              <div></div>
            </ModalIcon>
            <ModalTitle>{title}</ModalTitle>
            {content && <ModalContent>{content}</ModalContent>}
            <ModalButtonContainer hasCancleButton={!!cancleBtnText}>
              {cancleBtnText && (
                <>
                  <DefaultButton
                    category='secondaryMonochrome'
                    onClick={handleCloseBtnClick}
                  >
                    {cancleBtnText}
                  </DefaultButton>
                  <DefaultButton
                    category='primary'
                    onClick={handleConfirm}
                    {...confirmButtonProps}
                  >
                    {confirmBtnText}
                  </DefaultButton>
                </>
              )}
              {!cancleBtnText && (
                <StyledButton
                  category='primary'
                  onClick={handleConfirm}
                  {...confirmButtonProps}
                >
                  {confirmBtnText}
                </StyledButton>
              )}
            </ModalButtonContainer>
          </ModalWrapper>
        </ModalBackground>
      )}
    </>
  )
}

export default DefaultModal
