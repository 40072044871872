import useFormatText from '../../hooks/useFormatText'
import DefaultModal from '../../components/DefaultModal'
import { iconModalInfo } from '../../assets/icons'
import { useProjectContext } from '../../hooks/useProject'

interface BasicModalProps {
  onClose: () => void
}

const BasicModal = ({ onClose }: BasicModalProps) => {
  // const handleConfirmButtonClick = () => {
  //   window.open(`${process.env.REACT_APP_VPLATE_URL}/pricing`, '_blank')
  // }
  const { project } = useProjectContext()
  const renderLimit = project?.renderLimit ?? 3

  const title = useFormatText('FREE_RENDER_OVER_TITLE', {
    n: renderLimit
  })
  const content = useFormatText('FREE_RENDEER_OVER_DESCRIPTION')
  const confirmBtnText = useFormatText('FREE_RENDER_BACK')

  return (
    <DefaultModal
      icon={iconModalInfo}
      title={title}
      content={content}
      confirmBtnText={confirmBtnText}
      onClose={onClose}
      onConfirm={onClose}
    />
  )
}

export default BasicModal
