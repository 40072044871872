import React, { useState, useEffect, useCallback } from 'react'
import Cropper from 'react-easy-crop'

import cropImage from '../../utils/libs/cropImage'
import flipImage from '../../utils/libs/flipImage'
import { rotateImage } from '../../utils/libs/rotateImage'
import useLoading from '../../hooks/useLoading'
import ZoomButtons from './ZoomButtons'
import {
  CropButtonWrapper,
  CropWrapper,
  FunctionWrapper,
  IconFunction,
  LoadingWrapper,
  SecondaryTypography,
  Wrapper,
  Container
} from './_stylesCropImage'
import { getFuctions } from './constants'
import { useIntl } from 'react-intl'
import {
  IconReset,
  iconButtonMagicStick,
  iconButtonMagicStickDisabled
} from '../../assets/icons'
import styled, { css } from 'styled-components'
import DefaultButton from '../../components/DefaultButton'
import DefaultTooltip from '../../components/DefaultTootip'
import { useRemoveBgMutation } from './_queries'
import theme from '../../styles/theme'
import LottieIcon from '../../components/Lottie'
import { threeDotsLoading } from '../../assets/lottie'
import useFormatText from '../../hooks/useFormatText'
import useBreakpoint from '../../hooks/useBreakpoint'
import MoblieButtons from '../EditModals/MoblieButtons'

type SourceSizeType = {
  sourceWidth: number
  sourceHeight: number
}

type CropImageProps = {
  image?: string
  aspect?: number
  onCrop?(src: string): void
  restrictPosition?: boolean
  sourceSize: SourceSizeType
  onClose?: () => void
}

type ZoomPoint = {
  x: number
  y: number
}

type CroppedAreaPixelsPoint = {
  width: number
  height: number
  x: number
  y: number
}

const CropImage: React.FC<CropImageProps> = ({
  image: imageSrc = '',
  onCrop: handleCrop = () => {},
  sourceSize,
  onClose
}) => {
  const intl = useIntl()
  const { isBreakpoint } = useBreakpoint()
  const [image, setImage] = useState(imageSrc)
  const {
    data: bgUrls,
    isLoading: isRemoveBgLoading,
    mutate: removeBgMutate
  } = useRemoveBgMutation(imageSrc, setImage)
  const originalImageUrl = bgUrls?.originalImageUrl
  const removeBgImageUrl = bgUrls?.removedImageUrl
  const isCanNotRemoveBg = image === removeBgImageUrl
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [isCropping, setIsCropping] = useState(false)
  const { startLoading, endLoading, renderLoading, isLoading } = useLoading()
  const [minZoom, setMinZoom] = useState<number | null>(null)
  const [isZoom, setIsZoom] = useState(false)
  const [cropWidth, setCropWidth] = useState(0)
  const [cropHeight, setCropHeight] = useState(0)
  const [imageWidth, setImageWidth] = useState(0)
  const [imageHeight, setImageHeight] = useState(0)
  const [isWidthDropped, setIsWidthDropped] = useState(true)
  const disabled = !image || isCropping || isLoading

  const [croppedAreaPixels, setCroppedAreaPixels] =
    useState<CroppedAreaPixelsPoint | null>(null)
  const [aspect, setAspect] = useState(0)

  const cropArea = document.querySelector<HTMLElement>(
    '.reactEasyCrop_CropArea'
  )
  const tooltipContents = useFormatText(
    isCanNotRemoveBg
      ? 'IMAGE_BACKGROUND_REMOVAL_AFTER_DONE'
      : 'IMAGE_BACKGROUND_REMOVAL_INFO'
  )
  const resetText = useFormatText('IMAGE_BACKGROUND_REMOVAL_RESET')
  const IMAGE_BACKGROUND_REMOVAL_LOADING = useFormatText(
    'IMAGE_BACKGROUND_REMOVAL_LOADING'
  )

  const handleCropComplete = useCallback(
    (_croppedArea, croppedAreaPixels) => {
      let newCroppedArea = croppedAreaPixels
      if (zoom < 1) {
        newCroppedArea = {
          width: croppedAreaPixels.width * (1 / zoom),
          height: croppedAreaPixels.height * (1 / zoom)
        }
        newCroppedArea.x = isWidthDropped
          ? (croppedAreaPixels.width - newCroppedArea.width) / 2
          : 0
        newCroppedArea.y = isWidthDropped
          ? 0
          : (croppedAreaPixels.height - newCroppedArea.height) / 2
      }
      setCroppedAreaPixels(newCroppedArea)
    },
    [zoom, isWidthDropped]
  )

  useEffect(() => {
    if (!sourceSize) return
    const { sourceWidth, sourceHeight } = sourceSize
    setAspect(sourceWidth / sourceHeight)
  }, [sourceSize])

  useEffect(() => {
    startLoading()
  }, [])

  useEffect(() => {
    if (!cropWidth || !cropHeight || !imageWidth || !imageHeight) return
    let minZoom = 0.1
    const cropRatio = cropWidth / cropHeight
    const imageRatio = imageWidth / imageHeight
    if (cropRatio > imageRatio) {
      minZoom = cropHeight / imageHeight
      setIsWidthDropped(true)
    } else {
      minZoom = cropWidth / imageWidth
      setIsWidthDropped(false)
    }
    setMinZoom(minZoom + 0.01)
    endLoading()
  }, [cropWidth, cropHeight, imageWidth, imageHeight, endLoading])

  useEffect(() => {
    if (cropArea) {
      setCropWidth(cropArea.clientWidth)
      setCropHeight(cropArea.clientHeight)
    }
  }, [cropArea, cropArea?.clientHeight, cropArea?.clientWidth])

  const handleComplete = useCallback(async () => {
    setIsCropping(true)
    try {
      const croppedImage = await cropImage(image, croppedAreaPixels, 0)
      handleCrop(croppedImage)
    } catch (e) {
      console.error(e)
    }
    setTimeout(() => {
      setIsCropping(false)
    }, 255)
  }, [croppedAreaPixels, handleCrop, image])

  const handleCropChange = (item: ZoomPoint) => {
    const { x, y } = item
    if (isZoom) {
      setCrop({ x: 0, y: 0 })
    }
    if (!isZoom && zoom >= 1) {
      setCrop({ x, y })
    }
  }

  const handleZoomChange = (item: number) => {
    if (!minZoom) return
    if (item === zoom || (1 > item && zoom < minZoom)) return
    setIsZoom(true)
    const plusZoom = Math.min(item, 3)
    const minusZoom = Math.max(item, minZoom)
    setZoom(item > zoom ? plusZoom : minusZoom)
    setIsZoom(false)
  }

  const handleFlipX = useCallback(async () => {
    const result = await flipImage(image, 'flipX')
    setImage(result)
    endLoading()
  }, [image])

  const handleFlipY = useCallback(async () => {
    const result = await flipImage(image, 'flipY')
    setImage(result)
    endLoading()
  }, [image])

  const handleRotate = useCallback(async () => {
    const result = await rotateImage(image, 90)
    setImage(result)
    endLoading()
  }, [endLoading, image])

  const functions = getFuctions(handleFlipX, handleFlipY, handleRotate)

  const handleBgRemoverBtnClick = () => {
    if (!removeBgImageUrl) {
      removeBgMutate()
    } else {
      setImage(removeBgImageUrl)
    }
  }

  const handleResetBgRemoverBtnClick = async () => {
    if (!originalImageUrl) return
    setImage(originalImageUrl)
  }

  return (
    <Wrapper>
      <div style={{ width: '100%' }}>
        {!isBreakpoint('medium') && (
          <SecondaryTypography
            block
            style={{ marginBottom: 16, whiteSpace: 'pre-wrap' }}
          >
            {intl.formatMessage({ id: 'IMAGE_INPUT_EDIT_INFO' })}
          </SecondaryTypography>
        )}
        {isLoading && <LoadingWrapper>{renderLoading()}</LoadingWrapper>}
        <CropWrapper>
          <Container>
            {isRemoveBgLoading ? (
              <LoadiingWrapper>
                <LottieIcon
                  width={100}
                  height={100}
                  options={{ animationData: threeDotsLoading }}
                />
                <p>{IMAGE_BACKGROUND_REMOVAL_LOADING}</p>
              </LoadiingWrapper>
            ) : (
              <>
                <Cropper
                  {...{ image, crop, zoom, aspect }}
                  minZoom={minZoom || 1}
                  onCropChange={handleCropChange}
                  onZoomChange={handleZoomChange}
                  onCropComplete={handleCropComplete}
                  onMediaLoaded={() => {
                    const imageArea = document.querySelector<HTMLImageElement>(
                      '.reactEasyCrop_Contain'
                    )
                    if (imageArea) {
                      setImageWidth(imageArea.clientWidth)
                      setImageHeight(imageArea.clientHeight)
                    }
                  }}
                />
                <ZoomButtons
                  onClickZoomIn={() =>
                    setZoom((prev) => Math.min(prev + 0.2, 3))
                  }
                  onClickZoomOut={() =>
                    minZoom && setZoom((prev) => Math.max(prev - 0.2, minZoom))
                  }
                />
              </>
            )}
          </Container>
        </CropWrapper>
        <FunctionWrapper>
          <div>
            {functions.map(({ icon, text, onClick }) => {
              return (
                <IconFunction
                  key={text}
                  disabled={disabled}
                  icon={icon}
                  onClick={() => {
                    if (zoom !== 1) setZoom(1)
                    startLoading()
                    onClick()
                  }}
                >
                  <SecondaryTypography bold>
                    {intl.formatMessage({ id: text })}
                  </SecondaryTypography>
                </IconFunction>
              )
            })}
          </div>
        </FunctionWrapper>
        {isBreakpoint('medium') && (
          <SecondaryTypography
            block
            style={{ margin: '30px 0', whiteSpace: 'pre-wrap' }}
          >
            {intl.formatMessage({ id: 'IMAGE_INPUT_EDIT_INFO' })}
          </SecondaryTypography>
        )}
        <CropButtonWrapper>
          <RemoveBgButtonWrapper>
            <DefaultTooltip
              position={
                !isBreakpoint('medium')
                  ? { top: -24, left: 0 }
                  : { top: '50%', left: 'calc(100% + 9px)' }
              }
              tail={
                !isBreakpoint('medium')
                  ? { direction: 'bottom', position: { top: 25, left: 12 } }
                  : {
                      direction: 'left',
                      position: { top: '50%', left: -5 }
                    }
              }
              contents={tooltipContents}
              contentStyle={{ transform: 'translateY(-50%)' }}
              tailStyle={css`
                transform: translateY(-50%);
              `}
              touch
            >
              <DefaultButton
                category='compactMulticolored'
                onClick={handleBgRemoverBtnClick}
                disabled={isCanNotRemoveBg}
                style={{
                  cursor: isCanNotRemoveBg ? 'not-allowed' : 'pointer'
                }}
              >
                <BtnIcon disabled={isCanNotRemoveBg} />
                {useFormatText('IMAGE_BACKGROUND_REMOVAL')}
              </DefaultButton>
            </DefaultTooltip>
            {isCanNotRemoveBg && (
              <DefaultButton
                category='compactTransparent'
                style={{ fontSize: '12px' }}
                onClick={handleResetBgRemoverBtnClick}
              >
                <Reset width={16} height={16} />
                {resetText}
              </DefaultButton>
            )}
          </RemoveBgButtonWrapper>
          {!isBreakpoint('medium') && (
            <DefaultButton
              category='primary'
              style={{ margin: 0 }}
              onClick={() => {
                startLoading()
                handleComplete()
              }}
              disabled={disabled}
            >
              {intl.formatMessage({
                id: disabled
                  ? 'IMAGE_INPUT_DONE_BUTTON_PROCESS'
                  : 'IMAGE_INPUT_EDIT_DONE'
              })}
            </DefaultButton>
          )}
        </CropButtonWrapper>
      </div>
      {isBreakpoint('medium') && (
        <MoblieButtons
          onClickOk={() => {
            startLoading()
            handleComplete()
          }}
          onClickCancel={onClose}
          okButtonDisabled={disabled}
          cancelText='취소'
          okText='업로드'
        />
      )}
    </Wrapper>
  )
}

export default CropImage

const BtnIcon = styled.div<{ disabled?: boolean }>`
  width: 20px;
  height: 20px;
  background-image: url(${({ disabled }) =>
    disabled ? iconButtonMagicStickDisabled : iconButtonMagicStick});
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 100% 100%;
  margin-right: 4px;
`

const RemoveBgButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
`

const LoadiingWrapper = styled.div`
  z-index: 1;
  position: relative;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #aaaaaa;

  p {
    color: ${theme.colors.text['#666']};
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 150%;
  }
`

const Reset = styled(IconReset)`
  width: 18px;
  height: 18px;
  margin-left: 7px;
  margin-right: 5px;
`
