import styled, { css } from 'styled-components'
import theme from '../../styles/theme'
import useFormatText from '../../hooks/useFormatText'
import useBreakpoint from '../../hooks/useBreakpoint'
import Modal from '../../components/Modal'
import {
  InnerWrapper,
  TextContainer,
  TitleContainer
} from '../AiLoadingModal/_stylesAIScenario'
import AiSubtitle from '../AiLoadingModal/AiSubtitle'
import useToggle from '../../hooks/useToggle'
import {
  IconInfoLine,
  IconMediaImageList,
  IconScenario
} from '../../assets/icons'
import LottieIcon from '../../components/Lottie'
import useAIInfoSSE from '../AiLoadingModal/hooks/useAIInfoSSE'
import useScenarioTyping from '../AiLoadingModal/hooks/useScenarioTyping'
import {
  HeaderContainter,
  Info,
  LottieWrapper,
  MediaContainer,
  mediaItemStyle
} from '../AiLoadingModal/_stylesAIMedia'
import MediaItem from '../AiLoadingModal/MediaItem'
import { useEffect, useRef } from 'react'

interface GenerateScenarioLoadingProps {
  onClose: () => void
}

const GenerateScenarioLoading = ({ onClose }: GenerateScenarioLoadingProps) => {
  const { aiInfo } = useAIInfoSSE({
    onClose
  })

  const { isMobile } = useBreakpoint()
  const { isToggled, setIsToggled, toggle } = useToggle(true)
  const { isToggled: isMediaToggled, toggle: mediaToggle } = useToggle(true)
  const { copyAppealPoint } = useScenarioTyping({
    scenario: aiInfo?.scenario,
    isComplete: !!aiInfo?.isComplete,
    isReset: aiInfo?.status === -3,
    handleStartTyping: () => setIsToggled(true)
  })

  const scenarioScrollRef = useRef<HTMLDivElement | null>(null)
  const mediaScrollRef = useRef<HTMLDivElement | null>(null)

  const isKr = localStorage.getItem('locale')?.includes('ko') ?? false
  const scenarioProgress = aiInfo?.progress?.scenarioProgress
  const totalMediaLen = aiInfo?.totalMediaLen
  const isLoading = !scenarioProgress || !copyAppealPoint
  const hasMedia = !!totalMediaLen && totalMediaLen > 0

  useEffect(() => {
    if (scenarioScrollRef.current) {
      const element = scenarioScrollRef.current
      element.scrollTop = element.scrollHeight
    }
    if (mediaScrollRef.current) {
      const element = mediaScrollRef.current
      element.scrollTop = element.scrollHeight
    }
  }, [aiInfo])

  return (
    <Modal
      visible
      closable={false}
      onClose={onClose}
      notitleBar
      backgroundColor={`${theme.colors.black}80`}
      wrapperStyle={modalWrapperStyle}
      style={modalContentStyle}
    >
      <>
        <Container isKr={isKr}>
          <InnerWrapper isKr={isKr}>
            <TitleContainer>
              <AiSubtitle
                isToggled={isToggled}
                toggle={toggle}
                icon={<IconScenario />}
                subtitle={useFormatText('AI_LOADING_MODAL_STEP2')}
                progressValue={scenarioProgress || 0}
                progressText=''
                isShowArrow={isMobile}
                isShowProgress={isMobile}
                className='step-title'
              />
            </TitleContainer>
            {isToggled && !isLoading && (
              <>
                <div className='description'>
                  {useFormatText(
                    'GENERATE_SCENARIO_LOADING_MODAL_DESCRIPTION1'
                  )}
                </div>
                <GrayContainer
                  isEmpty={!copyAppealPoint}
                  hasMedia={hasMedia}
                  ref={scenarioScrollRef}
                >
                  {copyAppealPoint ||
                    useFormatText('AI_LOADING_MODAL_STEP2_PENDING')}
                </GrayContainer>
              </>
            )}
            {isLoading && (
              <GrayContainer isEmpty isLoading hasMedia={hasMedia}>
                <LottieIcon width={34} height={34} />
                {useFormatText('AI_LOADING_MODAL_STEP2_INFO2')}
              </GrayContainer>
            )}
          </InnerWrapper>
        </Container>
        {hasMedia && (
          <Container
            style={{ alignItems: 'flex-start', marginTop: isMobile ? 6 : 30 }}
            isKr={isKr}
          >
            <HeaderContainter>
              <AiSubtitle
                isToggled={isMediaToggled}
                toggle={mediaToggle}
                icon={<IconMediaImageList />}
                subtitle={useFormatText('AI_LOADING_MODAL_STEP3')}
                progressValue={aiInfo?.progress?.mediaProgress || 0}
                progressText=''
                isShowArrow={isMobile}
                isShowProgress={isMobile}
                className='step-title'
              />
            </HeaderContainter>
            {isMediaToggled && (
              <>
                <div className='description' style={{ marginTop: 12 }}>
                  {useFormatText(
                    'GENERATE_SCENARIO_LOADING_MODAL_DESCRIPTION2'
                  )}
                </div>
                <StyledMediaContainer ref={mediaScrollRef}>
                  {Array.isArray(aiInfo?.media) &&
                    aiInfo.media.map((media) => {
                      return (
                        <MediaItem
                          key={media.url}
                          media={media}
                          style={mediaItemStyle}
                        />
                      )
                    })}
                  {totalMediaLen &&
                    aiInfo?.media &&
                    !!aiInfo?.progress?.mediaProgress &&
                    aiInfo?.progress?.mediaProgress > 0 &&
                    totalMediaLen > aiInfo?.media?.length && (
                      <LottieWrapper>
                        <LottieIcon width={60} height={60} />
                      </LottieWrapper>
                    )}
                  {aiInfo?.media?.length === 0 && (
                    <div className='empty-media'>
                      <LottieIcon width={34} height={34} />
                      {useFormatText(
                        'GENERATE_SCENARIO_LOADING_MODAL_EMPTY_MEDIA'
                      )}
                    </div>
                  )}
                </StyledMediaContainer>
              </>
            )}
            {isMediaToggled && (
              <Info style={{ marginTop: 8 }}>
                <IconInfoLine />
                {useFormatText('AI_LOADING_MODAL_STEP3_INFO1')}
              </Info>
            )}
          </Container>
        )}
      </>
    </Modal>
  )
}

export default GenerateScenarioLoading

const Container = styled.div<{ isKr: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${theme.colors.white};

  .step-title {
    gap: 4px;
    color: ${theme.colors.black2};
    font-size: 20px;
    font-weight: 600;
    line-height: 120%;
  }

  .description {
    color: ${theme.colors.black};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;

    ${({ isKr }) =>
      !isKr &&
      css`
        white-space: pre-wrap;
      `};
  }

  ${({ theme }) =>
    theme.breakpoints.medium(css`
      white-space: pre-wrap;

      .step-title {
        font-size: 16px;
        gap: 8px;
      }
    `)};

  .empty-media {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
    color: ${theme.colors.text['#aaa']};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
  }
`

const GrayContainer = styled(TextContainer)<{ hasMedia: boolean }>`
  width: 100%;
  border-radius: 4px;
  padding: 20px 16px;

  ${({ hasMedia }) =>
    hasMedia
      ? css`
          height: 236px;
        `
      : css`
          height: 292px;
        `}

  ${({ isEmpty }) =>
    isEmpty &&
    css`
      display: flex;
      flex-direction: column;
      gap: 12px;
      color: ${theme.colors.text['#aaa']};
    `};

  ${({ theme }) =>
    theme.breakpoints.medium(css`
      height: 282px;
    `)};
`

const StyledMediaContainer = styled(MediaContainer)`
  height: 236px;
  border-radius: 4px;
  margin-top: 11px;
  padding: 20px 20px 20px 16px;
  flex: none;

  ${({ theme }) =>
    theme.breakpoints.medium(css`
      height: 262px;
    `)};
`

const modalWrapperStyle = css`
  &&& {
    width: calc(100% - 80px);
    max-width: 820px;
    height: fit-content;
    border-radius: 12px;
    margin: 0 auto;

    ${({ theme }) =>
      theme.breakpoints.large(css`
        bottom: unset;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      `)};

    ${({ theme }) =>
      theme.breakpoints.medium(css`
        width: 100%;
        height: 100vh;
        height: 100svh;
        max-height: none;
        position: static;
        transform: none;
        border-radius: 0;

        .scenario-wrapper {
          height: auto;
        }
      `)};
  }
`

const modalContentStyle = css`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 36px;
  overflow-y: auto;

  ${({ theme }) =>
    theme.breakpoints.medium(css`
      width: 100%;
      height: 100%;
      gap: 24px;
      padding: 30px 16px;
      justify-content: flex-start;
    `)};
`
