import { IconInfoLine, IconScenario } from '../../assets/icons'
import AiSubtitle from './AiSubtitle'
import useToggle from '../../hooks/useToggle'
import LottieIcon from '../../components/Lottie'
import useFormatText from '../../hooks/useFormatText'
import { Scenario } from '../../types/project'
import useScenarioTyping from './hooks/useScenarioTyping'
import {
  InnerWrapper,
  ScenarioInfo,
  TextContainer,
  TitleContainer,
  Wrapper
} from './_stylesAIScenario'

const AiScenario = ({
  scenario,
  isMobile,
  progress,
  isComplete,
  isReset,
  children
}: {
  scenario?: Scenario | null
  isMobile: boolean
  progress?: number
  isComplete: boolean
  isReset: boolean
  children?: React.ReactNode
}) => {
  const { isToggled, setIsToggled, toggle } = useToggle(!isMobile)

  const { copyAppealPoint } = useScenarioTyping({
    scenario,
    isComplete,
    isReset,
    handleStartTyping: () => setIsToggled(true)
  })

  const isLoading = !progress
  const isKr = localStorage.getItem('locale')?.includes('ko') ?? false

  return (
    <div>
      <Wrapper className='scenario-wrapper'>
        <InnerWrapper isKr={isKr}>
          <TitleContainer>
            <AiSubtitle
              isToggled={isToggled}
              toggle={toggle}
              icon={<IconScenario />}
              subtitle={useFormatText('AI_LOADING_MODAL_STEP2')}
              progressValue={progress || 0}
              progressText='2'
              isShowArrow={isMobile}
            />
            {!isMobile && isKr && (
              <ScenarioInfo>
                <IconInfoLine />
                {useFormatText('AI_LOADING_MODAL_STEP2_INFO1')}
              </ScenarioInfo>
            )}
          </TitleContainer>
          {isToggled && !isLoading && (
            <TextContainer isEmpty={!copyAppealPoint}>
              {copyAppealPoint ||
                useFormatText('AI_LOADING_MODAL_STEP2_PENDING')}
            </TextContainer>
          )}
          {isLoading && (
            <TextContainer isEmpty isLoading>
              <LottieIcon width={34} height={34} />
              {useFormatText('AI_LOADING_MODAL_STEP2_INFO2')}
            </TextContainer>
          )}
        </InnerWrapper>
        {children}
      </Wrapper>
      {isToggled && (isMobile || !isKr) && (
        <ScenarioInfo>
          <IconInfoLine />
          {useFormatText('AI_LOADING_MODAL_STEP2_INFO1')}
        </ScenarioInfo>
      )}
    </div>
  )
}

export default AiScenario
