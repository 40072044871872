import { useMemo, useRef } from 'react'

import {
  InfiniteIcon,
  MobileRemainingRenderingInnerWrapper,
  MobileRemainingRenderingWrapper,
  MobileStatusButton,
  PlanBadge,
  RemainingRenderingInnerWrapper,
  RemainingRenderingWrapper,
  RenderingCount
} from './_stylesHeader'
import { iconHeaderInfinite } from '../../assets/icons'
import useFormatText from '../../hooks/useFormatText'
import DefaultTooltip from '../../components/DefaultTootip'
import { css } from 'styled-components'
import { PLAN_TYPE, PlanType } from '../../types/constants'

interface RemainingRenderingProps {
  renderCnt?: number
  renderLimit?: number
  planType?: PlanType
  isMobile: boolean
  needCheck?: boolean
}

const RemainingRendering = ({
  renderCnt,
  renderLimit,
  planType,
  isMobile,
  needCheck
}: RemainingRenderingProps) => {
  const PLAN_FREE = useFormatText('PLAN_FREE')
  const PLAN_BASIC = useFormatText('PLAN_BASIC')
  const PLAN_ENTERPRISE = useFormatText('PLAN_ENTERPRISE')

  const wrapperRef = useRef<HTMLDivElement | null>(null)

  const LEFT_RENDER = useFormatText('LEFT_RENDER')

  const isFree = planType === PLAN_TYPE.FREE[0]
  const colorByPlan = useMemo(() => {
    if (planType === undefined) return
    if (PLAN_TYPE.FREE.some((type) => type === planType)) {
      return { color: '#323232', text: PLAN_FREE }
    } else if (PLAN_TYPE.BASIC.some((type) => type === planType)) {
      return { color: '#2569ED', text: PLAN_BASIC }
    } else if (PLAN_TYPE.ENTERPRISE.some((type) => type === planType)) {
      return { color: '#4717D1', text: PLAN_ENTERPRISE }
    } else {
      return
    }
  }, [planType, PLAN_FREE, PLAN_BASIC, PLAN_ENTERPRISE])

  const restRenderCount =
    typeof renderCnt === 'number' &&
    typeof renderLimit === 'number' &&
    needCheck
      ? String(renderLimit - renderCnt)
      : ''

  // const onClickWrapper = () => {
  //   if (isFree) linkToPricing()
  // }

  return (
    <>
      {!isMobile && (
        <RemainingRenderingWrapper>
          <DefaultTooltip
            position={{ top: 'calc(100% + 8px)', right: '0px' }}
            tail={{
              direction: 'top',
              position: { top: '-5%', right: '16px' }
            }}
            contents={useFormatText(
              'HEADER_RENDERING_BASIC_LIMIT_INFO_TOOLTIP'
            )}
            contentStyle={{
              display: isFree ? 'block' : 'none'
            }}
            textStyle={{ fontSize: '14px' }}
            tailStyle={css`
              transform: translateY(-50%);
            `}
            touch
          >
            <RemainingRenderingInnerWrapper ref={wrapperRef} isPointer={isFree}>
              {!isMobile && (
                <>
                  <p>{LEFT_RENDER}</p>
                  {isFree ? (
                    <RenderingCount>
                      <strong>{restRenderCount}</strong>
                      <span>/ {renderLimit}</span>
                    </RenderingCount>
                  ) : (
                    <InfiniteIcon icon={iconHeaderInfinite} />
                  )}
                </>
              )}
            </RemainingRenderingInnerWrapper>
          </DefaultTooltip>
        </RemainingRenderingWrapper>
      )}
      {isMobile && (
        <MobileRemainingRenderingWrapper>
          <PlanBadge bgColor={colorByPlan?.color}>
            {colorByPlan?.text}
          </PlanBadge>
          <MobileRemainingRenderingInnerWrapper isPointer={false}>
            <p>{LEFT_RENDER}</p>
            {isFree ? (
              <RenderingCount isMobile={isMobile}>
                <strong>{restRenderCount}</strong>
                <span>/ {renderLimit}</span>
              </RenderingCount>
            ) : (
              <InfiniteIcon icon={iconHeaderInfinite} />
            )}
          </MobileRemainingRenderingInnerWrapper>
          <MobileStatusButton isFree={isFree}>
            <DefaultTooltip
              position={{ top: 'calc(100% + 26px)', right: '-12px' }}
              tail={{
                direction: 'top',
                position: { top: '-5%', right: '16px' }
              }}
              wrapperStyle={{ backgroundColor: 'transparent' }}
              contents={useFormatText(
                'HEADER_RENDERING_BASIC_LIMIT_INFO_TOOLTIP'
              )}
              contentStyle={{
                display: isFree && restRenderCount === '0' ? 'block' : 'none'
              }}
              textStyle={{ fontSize: '14px' }}
              tailStyle={css`
                transform: translateY(-50%);
              `}
              touch
              always
            >
              <>
                {isFree
                  ? useFormatText('HEADER_RENDERING_INFINITY_START')
                  : useFormatText('HEADER_RENDERING_INFINITY_POSSIBLE')}
              </>
            </DefaultTooltip>
          </MobileStatusButton>
        </MobileRemainingRenderingWrapper>
      )}
    </>
  )
}

export default RemainingRendering
