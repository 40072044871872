import { useQuery } from 'react-query'
import { service } from '../../utils/api'
import Cookie from 'js-cookie'
import { COUNTRY_CODE } from '../../constants/cookie'

const fetchCountryCode = async () => {
  return await service().users.getUserLocation()
}

export const useCountryCodeQuery = () => {
  const { refetch: fetchCountry } = useQuery(
    'getCountryCode',
    fetchCountryCode,
    {
      cacheTime: Infinity,
      staleTime: Infinity,
      enabled: false,
      onSuccess: (countryCode) => {
        Cookie.set(COUNTRY_CODE, countryCode)
      },
      onError: (e) => {
        Cookie.set(COUNTRY_CODE, 'US')
        console.log(e)
      }
    }
  )

  return { fetchCountry }
}
