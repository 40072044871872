import styled, { css } from 'styled-components'
import theme from '../../styles/theme'
import Button from '../Button'

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;

  .title {
    color: ${theme.colors.black2};
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
  }
`

export const UploadButton = styled(Button)`
  position: relative;
  display: flex;
  width: 100%;
  height: 44px;
  padding: 17px 0px;
  justify-content: center;
  align-items: center;
  gap: 130px;
  flex-shrink: 0;
  border: none;
  border-radius: 24px;
  background-color: ${theme.colors.activeBg};
  font-size: 16px;

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 48px;
    transform: translateY(-50%);
    width: 1px;
    height: 21px;
    background-color: ${theme.colors.primary};
    opacity: 0.3;
  }

  & > span > img {
    width: 48px;
    height: 44px;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    border-top-right-radius: 24px;
    border-bottom-right-radius: 24px;
    padding: 10px 12px;
    cursor: pointer;
  }
`

export const PreviewWrapper = styled.div`
  width: 100%;
  margin-top: 30px;

  .empty {
    width: 100%;
    text-align: center;
    color: ${theme.colors.text.secondary};
    text-align: center;
    font-size: 13px;
    font-weight: 400;
    line-height: 140%;
    margin-top: 83px;
    margin-bottom: 95px;

    ${({ theme }) =>
      theme.breakpoints.medium(css`
        margin-bottom: 93px;
      `)}
  }
`

export const FilePreview = styled.div`
  width: 242px;
  height: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  margin: 12px auto 24px auto;

  img,
  video {
    max-width: 100%;
    max-height: 100%;
  }
`

export const ListWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;

  .container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
      height: 32px;
      padding: 6px 12px;
      border-color: ${theme.colors.lightGrey};

      span {
        gap: 4px;
        flex-shrink: 0;
      }
    }

    button > span,
    .info-text {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      color: ${theme.colors.text['#888']};
      font-size: 14px;
      font-weight: 500;
      line-height: 100%;
    }
  }

  .check-info {
    display: flex;
    align-items: center;
    gap: 4px;

    .check-count {
      color: ${theme.colors.primary};
      font-size: 16px;
      font-weight: 600;
    }

    .checkbox {
      width: 16px;
      height: 16px;
      border: 1px solid ${theme.colors.b6b6};
    }
  }

  .list-info {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  .count {
    color: ${theme.colors.text['#888']};
    font-size: 14px;
    font-weight: 400;
    line-height: 100%;
  }

  .list {
    width: 100%;
    min-height: 200px;
    margin-top: 8px;
    flex: 1;
    position: relative;

    .scroller {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 100%;
      overflow-y: auto;
      display: flex;
      flex-wrap: wrap;
      gap: 12px;
      align-content: baseline;

      .item {
        position: relative;
        width: calc(50% - 6px);
        height: fit-content;
      }

      ${theme.breakpoints.medium(css`
        position: absolute;
        top: 0;
        left: 0;
      `)};
    }
  }
`

export const itemStyle = css`
  width: auto;

  & > div {
    height: 102px;
  }
`

export const LoadingWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 100;
  transform: translate(-50%, -50%);
  width: 80px;
  height: 80px;
`

export const SkeletonItem = styled.div`
  display: flex;
  width: calc(50% - 6px);
  aspect-ratio: 16 / 9;

  & > div {
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 4px;

    .ant-skeleton-image {
      width: 100%;
      height: 100%;
    }
  }
`
