import styled, { css } from 'styled-components'

import theme from '../../styles/theme'
import DefaultButton from '../DefaultButton'

export const ModalBackground = styled.div`
  z-index: 99999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
`

export const ModalWrapper = styled.div<{ hasCancelButton: boolean }>`
  position: relative;
  width: 466px;
  display: flex;
  flex-direction: column;
  padding: 36px 24px;
  border-radius: 12px;
  background-color: ${theme.colors.white};
  box-shadow: 3px 4px 4px 0px rgba(0, 0, 0, 0.07);

  @media (max-width: 506px) {
    width: calc(100% - 40px);
    margin: 0 auto;
    padding: 24px 16px;
  }

  ${({ hasCancelButton }) =>
    !hasCancelButton &&
    css`
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    `};
`

export const ModalIcon = styled.div<{ icon: string }>`
  display: flex;
  width: 100%;
  height: 48px;
  justify-content: center;
  align-items: center;

  div {
    width: 48px;
    height: 100%;
    background-image: url(${({ icon }) => icon});
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 100% 100%;
  }

  @media (max-width: 506px) {
    height: 44px;

    div {
      width: 44px;
    }
  }
`

export const ModalTitle = styled.h2`
  width: 100%;
  text-align: center;
  margin-top: 16px;
  line-height: 140%;
  font-size: 18px;
  font-weight: 600;
  white-space: pre-wrap;
  color: ${theme.colors.text['#222']};

  @media (max-width: 506px) {
    font-size: 16px;
  }
`

export const ModalContent = styled.p`
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 140%;
  margin-top: 12px;
  white-space: pre-wrap;
  color: ${theme.colors.text['#555']};

  @media (max-width: 506px) {
    font-size: 13px;
  }
`

export const ModalButtonContainer = styled.div<{ hasCancleButton: boolean }>`
  display: flex;
  justify-content: center;
  margin-top: 24px;

  button + button {
    margin-left: 12px;

    path {
      stroke: ${theme.colors.white};
    }
  }

  @media (max-width: 506px) {
    margin-top: 20px;

    ${({ hasCancleButton }) => !hasCancleButton && `margin-top: 0px;`};
  }
`

export const CloseBtn = styled.div<{ icon: string }>`
  position: absolute;
  top: 20px;
  right: 20px;
  width: 24px;
  height: 24px;
  background-image: url(${({ icon }) => icon});
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 100% 100%;
  cursor: pointer;

  @media (max-width: 506px) {
    top: 16px;
    right: 16px;
  }
`

export const StyledButton = styled(DefaultButton)`
  position: absolute;
  left: 0;
  bottom: -38px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 44px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
`
