import { Dispatch, SetStateAction } from 'react'
import {
  AIInfos,
  // BgmType,
  Project,
  UsedPoint,
  UserLimit
} from '../../types/project'

import {
  iconSideBarAI,
  iconSideBarAIActive,
  // iconSideBarMainColor,
  // iconSideBarMainColorActive,
  // iconSideBarSubColor,
  // iconSideBarSubColorActive,
  // iconSideBarText,
  // iconSideBarTextActive,
  // iconSideBarMusic,
  // iconSideBarMusicActive,
  iconSideBarStorage,
  iconSideBarStorageActive
} from '../../assets/icons'

// import MusicPanel from '../../components/MusicPanel'
// import ColorPanel from '../../components/ColorPanel'
import StoragePanel from '../../components/StoragePanel'
import { SetProject } from '../../hooks/useProject'

// import { message } from 'antd'
import AIPanel from '../../components/AIPanel'
import { IntlShape } from 'react-intl'
// import { Music } from '../../utils/api/files'

type GetMenuParams = {
  intl: IntlShape
  project?: Project
  selectedDrawerIndex: number
  setSelectedDrawerIndex: Dispatch<SetStateAction<number>>
  setProject: SetProject | undefined
  isMutateLoading?: boolean
  setIsModified: Dispatch<SetStateAction<boolean>>
  handleOnclickAdPoint: (idx: UsedPoint) => void
  aiInfos: AIInfos
  isReset: boolean
  userLimit: UserLimit
  setIsReset: Dispatch<SetStateAction<boolean>>
}

export const getMenus = ({
  intl,
  // project,
  selectedDrawerIndex,
  setSelectedDrawerIndex,
  // setProject,
  // isMutateLoading,
  // setIsModified,
  handleOnclickAdPoint,
  aiInfos,
  // isReset,
  userLimit
}: // setIsReset
GetMenuParams) => {
  const isPlugin = (aiInfos?.adPlanning || [])?.length > 0
  const handleDrawerClose = () => {
    setSelectedDrawerIndex(-1)
  }

  // const changeColor = (index: number, newColor: string | undefined) => {
  //   if (newColor && project && Array.isArray(project?.changedColor)) {
  //     setIsModified(true)
  //     project.changedColor.splice(index, 1, newColor)

  //     setProject &&
  //       setProject({
  //         ...project,
  //         changedColor: project?.changedColor
  //       })
  //     message.success(intl.formatMessage({ id: 'SIDE_NAV_CHANGED' }))
  //   }
  // }

  // const handleChangeBgm = async (
  //   newBgmSrc: string | null,
  //   newBgm: Music | null
  // ) => {
  //   setIsModified(true)
  //   const copiedCur = { ...project } as Project
  //   const copiedCurBgm = { ...project?.bgm } as BgmType

  //   setProject &&
  //     copiedCur &&
  //     (await setProject({
  //       ...copiedCur,
  //       bgmUrl: newBgmSrc || '',
  //       bgm: {
  //         ...copiedCurBgm,
  //         musicTitle: newBgm?.musicTitle || null,
  //         _id: newBgm?._id || null
  //       }
  //     }))
  // }

  const contents = [
    // {
    //   isActive: selectedDrawerIndex === 1,
    //   icons: {
    //     default: iconSideBarMainColor,
    //     active: iconSideBarMainColorActive
    //   },
    //   title: intl.formatMessage({ id: 'SIDE_NAV_MAIN_COLOR' }),
    //   key: 'mainColor',
    //   onClick: () =>
    //     setSelectedDrawerIndex((current: number) => (current === 1 ? -1 : 1)),
    //   drawer: {
    //     visible: selectedDrawerIndex === 1,
    //     children: (
    //       <ColorPanel
    //         selectedDrawerIndex={selectedDrawerIndex}
    //         title='SIDE_NAV_MAIN_COLOR'
    //         isMutateLoading={isMutateLoading}
    //         isReset={isReset && selectedDrawerIndex === 1}
    //         setIsReset={setIsReset}
    //         defaultColor={project?.design.temColor[0]}
    //         currentColor={project?.changedColor[0]}
    //         onColorChange={(newColor) => changeColor(0, newColor)}
    //         handleDrawerClose={handleDrawerClose}
    //       />
    //     )
    //   }
    // },
    // {
    //   isActive: selectedDrawerIndex === 2,
    //   icons: {
    //     default: iconSideBarSubColor,
    //     active: iconSideBarSubColorActive
    //   },
    //   title: intl.formatMessage({ id: 'SIDE_NAV_SUB_COLOR' }),
    //   key: 'subColor',
    //   onClick: () =>
    //     setSelectedDrawerIndex((current) => (current === 2 ? -1 : 2)),
    //   drawer: {
    //     visible: selectedDrawerIndex === 2,
    //     children: (
    //       <ColorPanel
    //         selectedDrawerIndex={selectedDrawerIndex}
    //         title='SIDE_NAV_SUB_COLOR'
    //         isMutateLoading={isMutateLoading}
    //         isReset={isReset && selectedDrawerIndex === 2}
    //         setIsReset={setIsReset}
    //         defaultColor={project?.design.temColor[1]}
    //         currentColor={
    //           project?.changedColor[1] || project?.design.temColor[1]
    //         }
    //         onColorChange={(newColor) => changeColor(1, newColor)}
    //         handleDrawerClose={handleDrawerClose}
    //       />
    //     )
    //   }
    // },
    // {
    //   isActive: selectedDrawerIndex === 3,
    //   icons: {
    //     default: iconSideBarText,
    //     active: iconSideBarTextActive
    //   },
    //   title: intl.formatMessage({ id: 'SIDE_NAV_FONT_COLOR' }),
    //   key: 'fontColor',
    //   onClick: () =>
    //     setSelectedDrawerIndex((current) => (current === 3 ? -1 : 3)),
    //   drawer: {
    //     visible: selectedDrawerIndex === 3,
    //     children: (
    //       <ColorPanel
    //         selectedDrawerIndex={selectedDrawerIndex}
    //         title='SIDE_NAV_FONT_COLOR'
    //         isMutateLoading={isMutateLoading}
    //         isReset={isReset && selectedDrawerIndex === 3}
    //         setIsReset={setIsReset}
    //         defaultColor={project?.design.temColor[2]}
    //         currentColor={
    //           project?.changedColor[2] || project?.design.temColor[2]
    //         }
    //         onColorChange={(newColor) => changeColor(2, newColor)}
    //         handleDrawerClose={handleDrawerClose}
    //       />
    //     )
    //   }
    // },
    // {
    //   icons: {
    //     default: iconSideBarMusic,
    //     active: iconSideBarMusicActive
    //   },
    //   isActive: selectedDrawerIndex === 4,
    //   title: intl.formatMessage({ id: 'SIDE_NAV_BGM' }),
    //   key: 'bgm',
    //   onClick: () =>
    //     setSelectedDrawerIndex((current) => (current === 4 ? -1 : 4)),
    //   drawer: {
    //     visible: selectedDrawerIndex === 4,
    //     children: (
    //       <MusicPanel
    //         isMutateLoading={isMutateLoading}
    //         isReset={isReset && selectedDrawerIndex === 4}
    //         setIsReset={setIsReset}
    //         duration={project?.design?.videoDuration}
    //         changedBgmTitle={project?.bgm?.musicTitle}
    //         defaultMusicUrl={project?.design?.temPreviewVideo}
    //         currentMusicUrl={project?.bgmUrl}
    //         onChange={handleChangeBgm}
    //         visible={selectedDrawerIndex === 3}
    //         handleDrawerClose={handleDrawerClose}
    //       />
    //     )
    //   }
    // },
    {
      icons: {
        default: iconSideBarStorage,
        active: iconSideBarStorageActive
      },
      isActive: selectedDrawerIndex === 1,
      title: intl.formatMessage({ id: 'IMAGE_INPUT_FROM_STORAGE' }),
      key: 'storage',
      onClick: () =>
        setSelectedDrawerIndex((current) => (current === 1 ? -1 : 1)),
      drawer: {
        visible: selectedDrawerIndex === 1,
        children: (
          <StoragePanel
            isActive={selectedDrawerIndex === 1}
            handleDrawerClose={handleDrawerClose}
          />
        )
      }
    }
  ]

  if (isPlugin) {
    contents.unshift({
      isActive: selectedDrawerIndex === 0,
      icons: {
        default: iconSideBarAI,
        active: iconSideBarAIActive
      },
      title: intl.formatMessage({ id: 'SIDE_NAV_AI' }),
      key: 'auto',
      onClick: () =>
        setSelectedDrawerIndex((current: number) => (current === 0 ? -1 : 0)),
      drawer: {
        visible: selectedDrawerIndex === 0,
        children: (
          <AIPanel
            handleClick={handleOnclickAdPoint}
            handleDrawerClose={handleDrawerClose}
            aiInfos={aiInfos}
            userLimit={userLimit}
          />
        )
      }
    })
  }

  return contents
}

export default getMenus
