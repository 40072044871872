/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useRef, useState } from 'react'
import Modal, { ModalProps } from '../../components/Modal'
import Typography from '../../components/Typography'
import Input from '../../components/Input'

import { Checkbox } from 'antd'
import styled, { css, keyframes } from 'styled-components'
import { getInfoByLength } from './constants'
import { IconInfo } from '../../assets/icons'
import { useIntl } from 'react-intl'
import DefaultTooltip from '../../components/DefaultTootip'
import DefaultButton from '../../components/DefaultButton'
import checkXSS from '../../utils/libs/checkXSS'
import theme from '../../styles/theme'

type TextModalProps = ModalProps & {
  value?: string
  textLength?: number
  onComplete?(value: string): void
}

const TextModal = (props: TextModalProps) => {
  const { onClose, visible, value, onComplete, textLength = 8 } = props
  const intl = useIntl()
  const inputRef = useRef<HTMLInputElement>(null)
  const [isCheck, setIsCheck] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const [temp, setTemp] = useState('')
  const info = getInfoByLength(inputValue.length, textLength)
  const [isWarning, setIsWarning] = useState(false)

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value

    const result = checkXSS(newValue)

    if (result.length !== newValue.length) {
      setIsWarning(true)
      return
    }
    setIsWarning(false)
    setInputValue(newValue)
    setTemp(newValue)
  }

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault()
    if (onComplete) {
      onComplete(inputRef?.current?.value || '')
    }
  }

  useEffect(() => {
    if (visible) {
      setInputValue(value || '')
      setTemp(value || '')
      setIsCheck(value === ' ')
      setIsWarning(false)
      if (value === ' ') return
      setTimeout(() => {
        if (!inputRef.current) return
        inputRef.current.focus()
        inputRef.current.select()
      }, 0)
    }
  }, [value, visible])

  useEffect(() => {
    isCheck && setInputValue(' ')
    !isCheck && setInputValue(temp === ' ' ? '' : temp)
    !isCheck && inputRef.current?.focus()
  }, [isCheck, temp])

  return (
    <Modal
      noFullHeight
      title={intl.formatMessage({ id: 'TEXT_INPUT_TITLE' })}
      visible={visible}
      onClose={onClose}
      fitContent
      closeBtnType='round'
      wrapperStyle={css`
        height: auto;
      `}
    >
      <StyledForm onSubmit={handleSubmit}>
        <div style={{ padding: 16 }}>
          <Typography type='body2' center block>
            {info?.icon}
            <span style={{ marginLeft: 10 }}>
              {intl.formatMessage({ id: info?.text })}
            </span>
          </Typography>
        </div>
        <InputWrapper
          check={isCheck}
          onClick={() => isCheck && setIsCheck(false)}
          style={{
            marginBottom: isWarning ? 0 : 16
          }}
        >
          <WhiteText check={isCheck}>
            {intl.formatMessage({ id: 'TEXT_INPUT_BLANK_INFO' })}
          </WhiteText>
          <Input
            block
            inputSize='small'
            value={inputValue}
            ref={inputRef}
            placeholder={intl.formatMessage({ id: 'TEXT_INPUT_PLACEHOLDER' })}
            data-testid='input-text'
            disabled={isCheck}
            onChange={onChangeInput}
            onBlur={() => inputValue === ' ' && setIsCheck(true)}
          />
          {isWarning && (
            <ErrorMessage onAnimationEnd={() => setIsWarning(false)}>
              ' & _ () 를 제외한 특수문자는 입력하실 수 없습니다.
            </ErrorMessage>
          )}
        </InputWrapper>
        <CheckboxWrapper>
          <Checkbox
            checked={isCheck}
            style={{ fontWeight: 'bold' }}
            onClick={() => setIsCheck((prev) => !prev)}
          >
            {intl.formatMessage({ id: 'TEXT_INPUT_BLANK' })}
          </Checkbox>
          <DefaultTooltip
            position={{ top: 0, left: 30 }}
            tail={{ direction: 'left', position: { top: 6, left: -6 } }}
            width={155}
            contents={intl.formatMessage({ id: 'TEXT_INPUT_BLANK_TOOLTIP' })}
          >
            <CIconInfo />
          </DefaultTooltip>
        </CheckboxWrapper>
        <div style={{ textAlign: 'right' }}>
          <Typography type='caption1'>
            {inputValue.length}/{textLength}
          </Typography>
        </div>
        <ButtonWrapper>
          <div>
            <DefaultButton
              category='secondaryMonochrome'
              type='button'
              onClick={onClose}
            >
              {intl.formatMessage({ id: 'TEXT_INPUT_CLOSE' })}
            </DefaultButton>
          </div>
          <div>
            <DefaultButton category='secondaryMulticolored' type='submit'>
              {intl.formatMessage({ id: 'TEXT_INPUT_DONE' })}
            </DefaultButton>
          </div>
        </ButtonWrapper>
      </StyledForm>
    </Modal>
  )
}

export default TextModal

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100vw;
  max-width: 356px;
  margin: 0 auto;
`

const InputWrapper = styled.div<{ check: boolean }>`
  background-color: transparent;
  ${({ theme, check }) =>
    check &&
    `
      position: relative;
      background-color: ${theme.colors.input.disabled};
      
      input {
        background-color: ${theme.colors.input.disabled};
      }
    `};
`

const WhiteText = styled.div<{ check: boolean }>`
  position: absolute;
  top: 12px;
  left: 15px;
  color: ${({ theme }) => theme.colors.text.secondary};
  opacity: ${({ check }) => (check ? 0.9 : 0)};
`

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;

  .ant-checkbox-wrapper {
    font-size: 14px;

    ${({ theme }) =>
      theme.breakpoints.medium(css`
        font-size: 12px;
      `)}
  }

  .ant-checkbox + span {
    padding-right: 0px;
  }
`

const CIconInfo = styled(IconInfo)`
  width: 15px;
  height: 15px;
  margin-top: 4px;
  margin-left: 8px;

  ${({ theme }) =>
    theme.breakpoints.medium(css`
      margin-top: 6px;
    `)}
`

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
`

const fadeInOut = keyframes`
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.99;
  }
  100% {
    opacity: 0;
  }

`

export const ErrorMessage = styled.span`
  color: ${theme.colors.brand.primary[500]};
  font-size: 12px;
  animation: ${fadeInOut} 1s linear;
`
