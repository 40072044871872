import styled, { css } from 'styled-components'
import { tabletMedia } from './constants'

import { useEffect, useRef } from 'react'
import Modal from '../../components/Modal'
import theme from '../../styles/theme'
import AiTitle from './AiTitle'
import AppealPoints from './AppealPoints'
import AiMedia from './AiMedia'
import AiScenario from './AiScenario'
import useBreakpoint from '../../hooks/useBreakpoint'
// import LeaveButton from './LeaveButton'
// import useTablet from './hooks/useTablet'
import ErrorModal from './ErrorModal'
import useAIInfoSSE from './hooks/useAIInfoSSE'

export interface AiLoadingModalProps {
  onClose: () => void
}

const AiLoadingModal = ({ onClose }: AiLoadingModalProps) => {
  const { isMobile } = useBreakpoint()
  // const { isTablet } = useTablet()

  const { aiInfo, isError, isPendingError, isRetryFailError } = useAIInfoSSE({
    onClose
  })

  const progress = aiInfo?.progress
  const isMediaStep = isMobile
    ? !!aiInfo?.totalMediaLen
    : progress?.mediaProgress !== undefined &&
      progress?.scenarioProgress >= 100 &&
      !!aiInfo?.totalMediaLen

  const isAutoRender = aiInfo?.autoRender

  const scenarioRef = useRef<HTMLDivElement | null>(null)
  const mediaRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (!progress) return
    if (progress?.scenarioProgress >= 100 && !!aiInfo?.totalMediaLen) {
      mediaRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'end'
      })
      return
    }

    if (progress?.scenarioProgress > 0 && progress?.mediaProgress < 1) {
      scenarioRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'end'
      })
    }
  }, [aiInfo, progress])

  return (
    <Modal
      visible
      closable={isError || aiInfo?.progress?.isComplete}
      onClose={onClose}
      notitleBar
      backgroundColor={`${theme.colors.black}80`}
      wrapperStyle={modalWrapperStyle}
      style={contentStyle}
    >
      <Wrapper>
        <AiTitle
          isMobile={isMobile}
          progress={progress}
          isPendingError={isPendingError}
          mediaTotalLength={aiInfo?.totalMediaLen}
          isAutoRender={isAutoRender}
        />
        <Contents>
          <Container isMediaStep={isMediaStep}>
            <AppealPoints
              progress={progress?.adPlanningProgress}
              adPlanning={aiInfo?.adPlanning}
              isPendingError={isPendingError}
              isMobile={isMobile}
              isReset={aiInfo?.status === -1}
            />
            <AiScenario
              scenario={aiInfo?.scenario}
              isMobile={isMobile}
              progress={progress?.scenarioProgress}
              isComplete={!!progress?.isComplete}
              isReset={aiInfo?.status === -3}
            >
              <div
                style={{ width: '100%', height: 'auto' }}
                ref={scenarioRef}
              />
            </AiScenario>
          </Container>
          {isMediaStep && (
            <>
              <AiMedia
                medias={aiInfo?.media}
                isMobile={isMobile}
                progress={progress?.mediaProgress}
                mediaTotalLength={aiInfo?.totalMediaLen}
                isReset={aiInfo?.status === -5}
              />
              <div style={{ width: '100%', height: 'auto' }} ref={mediaRef} />
            </>
          )}
        </Contents>
        {/* {(isTablet || isMobile) && <LeaveButton isAutoRender={isAutoRender} />} */}
        {isRetryFailError && <ErrorModal onClose={onClose} />}
      </Wrapper>
    </Modal>
  )
}

export default AiLoadingModal

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;

  ${tabletMedia(css`
    flex-direction: column;
  `)};

  ${({ theme }) =>
    theme.breakpoints.medium(css`
      display: grid;
      grid-template-rows: auto 1fr auto;
    `)};
`

export const modalWrapperStyle = css`
  &&& {
    width: 100%;
    max-width: 1276px;
    height: calc(100% - 40px);
    max-height: 832px;
    border: none;
    border-radius: 12px;
    box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.06);
    padding: 36px 24px;

    ${tabletMedia(css`
      width: calc(100% - 204px);
      max-width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      @media (max-width: 900px) {
        width: calc(100% - 80px);
      }
    `)};

    ${({ theme }) =>
      theme.breakpoints.medium(css`
        padding: 0;
      `)};

    ${({ theme }) =>
      theme.breakpoints.small(css`
        height: 100%;
        max-height: none;
        top: unset;
        bottom: 0;
        border-radius: 0;
      `)};
  }
`

const MOBILE_FOOTER_HEIGHT = 62

export const Contents = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;

  ${tabletMedia(css`
    height: 100%;
    overflow-y: auto;
  `)};

  ${({ theme }) =>
    theme.breakpoints.medium(css`
      height: calc(100% - ${MOBILE_FOOTER_HEIGHT}px);
      gap: 30px;
      padding: 0 16px 14px 16px;
      overflow-y: auto;
    `)}
`

export const Container = styled.div<{ isMediaStep: boolean }>`
  width: 100%;
  height: ${({ isMediaStep }) => (isMediaStep ? '376px' : '100%')};
  display: flex;
  transition: all 0.5s ease-in-out;

  ${({ isMediaStep }) =>
    tabletMedia(css`
      height: ${isMediaStep ? 'max-content' : '100%'};
      gap: 48px;

      & > div {
        height: ${isMediaStep ? '305px' : '100%'};
        flex: 1 0 calc(50% - 24px);
        padding: 0;
      }

      @media (max-width: 1065px) {
        height: max-content;
        flex-wrap: wrap;

        & > div {
          height: 305px;
        }
      }
    `)};

  ${({ theme }) =>
    theme.breakpoints.medium(css`
      height: max-content;
      flex-direction: column;
      gap: 30px;
    `)};
`

export const contentStyle = css`
  width: 100%;
  height: 100%;
  padding: 0;
`
