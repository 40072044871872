import { useEffect, useRef, useState } from 'react'
import {
  useStorageInfiniteQuery
  // useDeleteAssetsMutation
} from './_queries'
// import FilterDropdown from './FilterDropdown'
import styled from 'styled-components'
import LottieIcon from '../../../components/Lottie'
import { useInView } from 'react-intersection-observer'
import Button from '../../../components/Button'
import theme from '../../../styles/theme'
import {
  IconDrive
  // IconPenCil,
  // IconTrashcan
} from '../../../assets/icons'
import useFormatText from '../../../hooks/useFormatText'
import FileItem from './FileItem'
import StorageSize from './StorageSize'
import EmptyText from '../../../components/EmptyText'
// import useBreakpoint from '../../../hooks/useBreakpoint'
// import CheckInfo from './CheckInfo'
import { SkeletonItem } from '../../../components/StoragePanel/styles'
import { Skeleton } from 'antd'
import useStorageFile from './hooks/useStorageFile'

interface StorageProps {
  type: 'image' | 'video'
  onClickItem: (file: any) => void
}

const Storage = ({ type, onClickItem }: StorageProps) => {
  // const { isBreakpoint } = useBreakpoint()
  // const [checkedList, setCheckedList] = useState<any[]>([])
  // const [order, setOrder] = useState<0 | 1>(0)
  const [order] = useState<0 | 1>(0)
  const [files, setFiles] = useState<any[]>([])

  // const [isEditMode, setIsEditMode] = useState<boolean>(false)

  const fileRef = useRef<HTMLInputElement>(null)
  const { ref: observeRef, inView } = useInView({
    threshold: 0,
    triggerOnce: true
  })
  const { storageData, fetchNextPage, isFetching, isLoading, hasNext } =
    useStorageInfiniteQuery({
      type,
      limit: 20,
      order,
      files,
      setFiles
    })

  // const { deleteAssetsMutate } = useDeleteAssetsMutation(setCheckedList)

  const { notUploadedFiles, totalItems, handleInputFileChange } =
    useStorageFile({
      files,
      setFiles,
      storageData,
      queryKey: ['assets', type, order]
    })

  // const handleEditButtonClick = () => {
  //   if (isEditMode) {
  //     if (checkedList.length === 0) {
  //       setIsEditMode((prev) => !prev)
  //       return
  //     }
  //     const checkIds = checkedList.map((file) => file?._id)

  //     if (!checkIds.every((id) => id)) {
  //       setFiles((prev) => prev.filter((file) => !checkedList.includes(file)))
  //     }

  //     deleteAssetsMutate(checkIds)
  //   }

  //   setIsEditMode((prev) => !prev)
  // }

  const handleItemClick = (file: any) => {
    // if (!isEditMode && file?.originalUrl) {
    onClickItem(file)
    //   return
    // }
    // if (checkedList.includes(file)) {
    //   setCheckedList((prev) => {
    //     return prev.filter((item) => {
    //       if (item?._id && file?._id) {
    //         return item._id !== file._id
    //       }
    //       return item.name !== file.name
    //     })
    //   })
    // } else {
    //   setCheckedList((prev) => [...prev, file])
    // }
  }

  useEffect(() => {
    inView && fetchNextPage()
  }, [inView])

  return (
    <>
      <Wrapper>
        {!isLoading ? (
          <>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                flexWrap: 'wrap',
                marginBottom: 12
              }}
            >
              {/* <FilterDropdown order={order} setOrder={setOrder} /> */}
              <div style={{ display: 'flex' }}>
                {/* {!isBreakpoint('large') && checkedList.length > 0 && (
                  <CheckInfo checkedList={checkedList} />
                )}
                <StyledButton
                  onClick={handleEditButtonClick}
                  style={{ marginRight: 8 }}
                >
                  {isEditMode ? <IconTrashcan /> : <IconPenCil />}
                  {useFormatText(isEditMode ? 'DELETE' : 'EDIT')}
                </StyledButton> */}
                <StyledButton
                  onClick={() => fileRef.current && fileRef.current.click()}
                >
                  <IconDrive />
                  {useFormatText('MY_DRIVE')}
                </StyledButton>
              </div>
            </div>
            {/* {isBreakpoint('large') && checkedList.length > 0 && (
              <CheckInfo checkedList={checkedList} />
            )} */}
            <Container>
              <Row>
                {Array.isArray(notUploadedFiles) &&
                  notUploadedFiles.map((notUploadedFile) => (
                    <Col
                      key={`${notUploadedFile?._id || notUploadedFile?.name}`}
                    >
                      <SkeletonItem
                        style={{
                          width: '100%',
                          maxHeight: 126
                        }}
                      >
                        <Skeleton.Image />
                      </SkeletonItem>
                    </Col>
                  ))}
                {(totalItems || []).length > 0 && (
                  <>
                    {totalItems.map((file: any) => {
                      // const isChecked = !!checkedList.find((item) => {
                      //   return item?._id
                      //     ? item?._id === file?._id
                      //     : item?.name == file?.name
                      // })

                      return (
                        <Col key={file._id}>
                          <FileItem
                            file={file}
                            // isChecked={isChecked}
                            isChecked={false}
                            onClick={() => handleItemClick(file)}
                            // showCheckbox={isEditMode}
                            showCheckbox={false}
                          />
                        </Col>
                      )
                    })}
                    {isFetching ? (
                      <div style={{ margin: '20px auto' }}>
                        <LottieIcon />
                      </div>
                    ) : (
                      hasNext && <div style={{ margin: 20 }} ref={observeRef} />
                    )}
                  </>
                )}
              </Row>
              {(totalItems || []).length === 0 && (
                <EmptyText
                  text={useFormatText('STORAGE_EMPTY_TEXT')}
                  style={{ height: 'calc(100% - 54px)' }}
                />
              )}
              <StorageSize />
            </Container>
          </>
        ) : (
          <LoadingWrapper>
            <LottieIcon />
          </LoadingWrapper>
        )}
        <input
          ref={fileRef}
          multiple
          accept={
            type !== 'image'
              ? 'video/mp4'
              : 'image/jpeg, image/jpg, image/png, image/webp'
          }
          type='file'
          onChange={handleInputFileChange}
          style={{ display: 'none' }}
        />
      </Wrapper>
    </>
  )
}

export default Storage

const Wrapper = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
`

const LoadingWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 100;
  transform: translate(-50%, -50%);
  width: 80px;
  height: 80px;
`

const GAP = 12
const Row = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-height: calc(100% - 72px);
  display: flex;
  flex-wrap: wrap;
  gap: ${GAP}px;
  overflow-x: hidden;
  overflow-y: auto;
`

const Col = styled.div`
  position: relative;
  min-width: calc(50% - ${GAP / 2}px);
  max-width: calc(50% - ${GAP / 2}px);
  height: max-content;
  max-height: 125px;
  flex: 1;
  user-select: none;

  img,
  video {
    width: 100%;
    height: 100%;
    aspect-ratio: 16 / 9;
    object-fit: cover;
    cursor: pointer;
  }

  .checkbox-wrapper {
    position: absolute;
    top: 8px;
    left: 8px;

    .checkbox {
      width: 16px;
      height: 16px;
    }
    z-index: 1;
  }
`

const StyledButton = styled(Button)`
  height: 32px;
  padding: 6px 12px;
  border-radius: 4px;
  border: 1px solid ${theme.colors.lightGrey};

  svg {
    width: 18px;
    height: 18px;
  }

  span {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    flex-shrink: 0;
    color: ${theme.colors.text['#888']};
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 100%;
    letter-spacing: -0.28px;
  }
`

const Container = styled.div`
  position: relative;
  flex: 1;
`
