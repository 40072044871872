import styled, { css } from 'styled-components'
import theme from '../../styles/theme'
import Tabs from '../Tabs'
// import AIImageList from './AIImageList'
import AIDataList from './AIDataList'
import { AIInfos, UsedPoint, UserLimit } from '../../types/project'
import { useIntl } from 'react-intl'
import PanelTitle from '../PanelTitle'
import useBreakpoint from '../../hooks/useBreakpoint'
interface AIPanelProps {
  handleClick: (idx: UsedPoint) => void
  handleDrawerClose: () => void
  aiInfos: AIInfos
  userLimit: UserLimit
}

const AIPanel = ({
  handleClick,
  handleDrawerClose,
  aiInfos,
  userLimit
}: AIPanelProps) => {
  const intl = useIntl()
  const { isMobile } = useBreakpoint()

  const tabList = [
    {
      title: intl.formatMessage({ id: 'SIDE_NAV_AI_CONTENT_TAP1' }),
      children: (
        <AIDataList
          adPlanning={aiInfos?.adPlanning}
          usedPoint={aiInfos.usedPoint}
          handleClick={handleClick}
          isValidUserLimit={!!userLimit}
          scenarioLimit={userLimit?.monthlyScenarioGen}
          scenarioCount={userLimit?.usedScenarioGen}
        />
      )
    }
    // {
    //   title: intl.formatMessage({ id: 'SIDE_NAV_AI_CONTENT_TAP2' }),
    //   children: (
    //     <AIImageList
    //       scenario={aiInfos?.scenario}
    //       aiImages={aiInfos?.images}
    //       usedPoint={aiInfos.usedPoint}
    //     />
    //   )
    // }
  ]

  return (
    <Wrapper>
      {isMobile && (
        <PanelTitle
          title='SIDE_NAV_AI'
          handleDrawerClose={handleDrawerClose}
          wrapperStyle={{ height: '100%' }}
        >
          <TabWrapper>
            <Tabs tabs={tabList} />
          </TabWrapper>
        </PanelTitle>
      )}
      {!isMobile && (
        <TabWrapper>
          <Tabs tabs={tabList} />
        </TabWrapper>
      )}
    </Wrapper>
  )
}

export default AIPanel

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 auto;

  .tabs-wrapper {
    display: flex;
    flex-direction: column;
  }
  .tab-content {
    height: auto;
    flex: 1;
  }
`

const TabWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  color: ${theme.colors.text.secondary};

  ${({ theme }) =>
    theme.breakpoints.medium(css`
      height: calc(100% - 44px);
    `)};
`
