import React, { createContext, useContext, useEffect, useState } from 'react'

import { service } from '../../utils/api'
import { useCountryCodeQuery } from './_queries'
import { useAiStore } from '../../stores/ai'
import Cookie from 'js-cookie'
import { COUNTRY_CODE, USER_INFO } from '../../constants/cookie'
import { User } from '../../types/user'

export type Locale = 'ko' | 'en'
export const LOCALE = {
  KO: 'ko',
  EN: 'en'
} as const

interface AuthHelperProps {
  projectId: string
  children: React.ReactNode
}

interface Context {
  userInfo: User
}

const context = createContext<Context | null>(null)
export const useUserInfo = () => useContext(context) as Context

const AuthHelper = ({ projectId, children }: AuthHelperProps) => {
  const userInfoCookie = Cookie.get(USER_INFO)
  const userInfo = userInfoCookie ? JSON.parse(userInfoCookie) : {}

  const [next, setNext] = useState(false)
  const { setAiInfos } = useAiStore()

  const { fetchCountry } = useCountryCodeQuery()

  const checkAuth = async () => {
    const result = await service().users.getReqId(projectId)

    if (result?._id) {
      setAiInfos({ ...result })
    }

    const countryCode = Cookie.get(COUNTRY_CODE)

    if (!countryCode) {
      await fetchCountry()
    }
    setNext(true)
  }

  useEffect(() => {
    checkAuth()
  }, [])

  return (
    <>
      {next && (
        <context.Provider
          value={{
            userInfo
          }}
        >
          {children}
        </context.Provider>
      )}
    </>
  )
}

export default AuthHelper
