import styled, { keyframes } from 'styled-components'
import theme from '../../styles/theme'
import { IconChecked } from '../../assets/icons'

interface CheckboxProps {
  label?: string
  checked: boolean
  onClick?: (file: any) => void
  clickAble?: boolean
}

const StorageCheckbox = ({
  label,
  checked = false,
  onClick,
  clickAble = true
}: CheckboxProps) => {
  return (
    <Wrapper
      className='checkbox-wrapper'
      onClick={onClick}
      clickAble={clickAble}
    >
      <StyledCheckbox checked={checked} className='checkbox'>
        <IconChecked />
      </StyledCheckbox>
      {label}
    </Wrapper>
  )
}

export default StorageCheckbox

const Wrapper = styled.div<{ clickAble?: boolean }>`
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  ${({ clickAble }) => !clickAble && `pointer-events: none;`};
`

const checkAnimation = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
`

const StyledCheckbox = styled.div<{ checked?: boolean }>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  background-color: ${({ checked }) =>
    checked ? theme.colors.primary : theme.colors.white};
  border-radius: 2px;
  border: 1.5px solid
    ${({ checked }) =>
      checked ? theme.colors.primary : theme.colors.text.link};
  transition: all 0.2s;
  animation: ${(props) => (props.checked ? checkAnimation : 'none')} 0.4s;

  svg {
    visibility: ${({ checked }) => (checked ? 'visible' : 'hidden')};
  }
`
