import {
  IconDanger,
  IconInfo,
  IconSuccess,
  IconWarning
} from '../../assets/icons'
interface ConvertProps {
  url: string
  type: 'image' | 'video'
}

export const convertURLtoFile = async ({ url, type }: ConvertProps) => {
  const response = await fetch(url)
  const data = await response.blob()
  const ext = url.split('.').pop() // url 구조에 맞게 수정할 것
  const filename = url.split('/').pop() // url 구조에 맞게 수정할 것
  const metadata = { type: `${type}/${ext}` }
  const file = new File([data], filename!, metadata)
  return file
}

export const getDuration = (file: File): Promise<number> =>
  new Promise((resolve, reject) => {
    try {
      const video = document.createElement('video')
      video.preload = 'metadata'
      video.src = URL.createObjectURL(file)

      video.onloadedmetadata = () => {
        window.URL.revokeObjectURL(video.src)
        resolve(video.duration)
      }

      video.onerror = (error) => reject(error)
    } catch (error) {
      reject(error)
    }
  })

export const getInfoByLength = (
  length: number,
  textLength: number,
  size = 13
) => {
  if (length <= 0) {
    return {
      icon: <IconInfo width={size} height={size} />,
      text: 'TEXT_INPUT_GUIDE'
    }
  }
  if (length > 0 && length <= textLength) {
    return {
      icon: <IconSuccess width={size} height={size} />,
      text: 'TEXT_INPUT_GOOD'
    }
  }
  if (length > textLength && length <= 50) {
    return {
      icon: <IconWarning width={size} height={size} />,
      text: 'TEXT_INPUT_WARN'
    }
  }
  if (length > 50) {
    return {
      icon: <IconDanger width={size} height={size} />,
      text: 'TEXT_INPUT_BAD'
    }
  }
  return null
}

const accepts = [
  'image/jpeg',
  'image/jpg',
  'image/png',
  'image/webp',
  'video/mp4'
]

const MAX_FILE_SIZE = {
  image: 10 * 1024 * 1024,
  video: 500 * 1024 * 1024
} as const

/** 파일 크기, 확장자 체크 */
export const checkFiles = (files: File[]) => {
  const filteredFiles: any = []
  const errorMessages: string[] = []

  if (!files) return { filteredFiles, errorMessages }
  Array.from(files).forEach((file) => {
    const fileType = file.type.split('/')[0] as keyof typeof MAX_FILE_SIZE

    if (!accepts.includes(file.type)) {
      errorMessages.push('AI_FILE_UPLOAD_ERROR2')
      return
    }
    if (file.size > MAX_FILE_SIZE[fileType]) {
      errorMessages.push('AI_FILE_UPLOAD_ERROR1')
      return
    }
    filteredFiles.push(file)
  })

  return {
    filteredFiles,
    errorMessages
  }
}
