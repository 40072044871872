import { DefaultTheme, StyledComponentProps } from 'styled-components'

import LottieIcon from '../Lottie'
import { ButtonCompo, ChildrenWrapper, LottieWrapper } from './styles'

export type ButtonCategory =
  | 'primary'
  | 'secondaryMonochrome'
  | 'secondaryMulticolored'
  | 'compactMonochrome'
  | 'compactMulticolored'
  | 'compactTransparent'
  | 'large'

export type ButtonProps = {
  category: ButtonCategory
  type?: 'button' | 'submit' | 'reset'
  loading?: boolean
  fullWidth?: boolean
}

const DefaultButton = ({
  children,
  loading = false,
  type = 'button',
  fullWidth,
  ...props
}: StyledComponentProps<'button', DefaultTheme, ButtonProps, never>) => {
  return (
    <ButtonCompo {...props} type={type} fullWidth={fullWidth} loading={loading}>
      <ChildrenWrapper loading={loading}>{children}</ChildrenWrapper>
      {loading && (
        <LottieWrapper>
          <LottieIcon width={15} height={15} />
        </LottieWrapper>
      )}
    </ButtonCompo>
  )
}

export default DefaultButton
