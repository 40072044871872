import React, { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import { Range as ReactRange } from 'react-range'
import {
  RenderThumbContainer,
  RenderTrackContainer
} from '../MusicPanel/styles'
import { IconTrackLeft, IconTrackRight } from '../../assets/icons'

const Range: React.FC<{
  background?: string
  originalDuration?: number
  duration?: number
  value?: number
  onChange?(value: number): void
  style?: React.CSSProperties
}> = ({
  background,
  value: valueFromProps = 0,
  onChange: handleChange,
  originalDuration = 100,
  duration = 10,
  style
}) => {
  const [value, setValue] = useState(valueFromProps + duration / 2)

  const handleSliderChange = useCallback(
    (newValue: number) => {
      if (newValue - duration / 2 <= 0) {
        setValue(duration / 2)
        return
      }
      if (newValue + duration / 2 >= originalDuration) {
        setValue(originalDuration - duration / 2)
        return
      }
      setValue(newValue)
    },
    [duration, originalDuration]
  )

  // [TODO]: 추후 수정 필요
  // 23/12/27: originalDuration보다 value가 커 오류 발생하여 임시방편으로 수정함
  const checkedValue = value > originalDuration ? 0 : value

  useEffect(() => {
    if (handleChange) {
      handleChange(value - duration / 2)
    }
  }, [value])

  useEffect(() => {
    setValue(valueFromProps + duration / 2)
  }, [valueFromProps])

  return (
    <Wrapper style={style}>
      <ReactRange
        min={0}
        max={originalDuration}
        step={0.1}
        values={[checkedValue]}
        onChange={([newValue]) => {
          handleSliderChange(newValue)
        }}
        renderTrack={({ props, children }) => (
          <RenderTrackContainer
            background={background}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
            style={{
              ...props.style
            }}
          >
            {children}
          </RenderTrackContainer>
        )}
        renderThumb={({ props }) => (
          <RenderThumbContainer
            width={(duration / originalDuration) * 100}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
            style={{
              ...props.style
            }}
          >
            <IconTrackLeft className='track-left' />
            <IconTrackRight className='track-right' />
          </RenderThumbContainer>
        )}
      />
    </Wrapper>
  )
}

export default Range

const Wrapper = styled.div`
  width: 100%;
  height: auto;
  position: relative;
`
