import React from 'react'
import useUploadFiles from './useUploadFiles'
import { checkFiles } from '../../constants'
import { useIntl } from 'react-intl'
import { IconCircleCaution } from '../../../../assets/icons'
import useToast from '../../../../hooks/useToast'

const useStorageFile = ({
  files,
  setFiles,
  storageData,
  queryKey
}: {
  files: any[]
  setFiles: React.Dispatch<React.SetStateAction<any[]>>
  storageData: any[]
  queryKey: any[]
}) => {
  const intl = useIntl()
  const { renderToast } = useToast()

  const uploadingFiles = files.filter((item) => item?.uploadProgress > 0)
  const notUploadedFiles = files.filter((item) => !item?.uploadProgress)
  const totalItems = [...uploadingFiles, ...storageData]

  const { uploadFile } = useUploadFiles({
    setFiles,
    queryKey,
    limit: 20
  })

  const uploadFilesSequentially = async (files: any[], currentIndex = 0) => {
    if (currentIndex >= files.length) return
    const currentFile = files[currentIndex]

    try {
      await uploadFile(currentFile)
      uploadFilesSequentially(files, currentIndex + 1)
    } catch (error) {
      console.error(`Error uploading file: ${currentFile.name}`, error)
      uploadFilesSequentially(files, currentIndex + 1)
    }
  }

  const handleInputFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (!event?.target?.files) return
    const newFiles = Array.from(event?.target?.files)
    const { filteredFiles, errorMessages } = checkFiles(newFiles)

    errorMessages.forEach((message) => {
      const formatMessage = intl.formatMessage({ id: message })

      renderToast({
        icon: <IconCircleCaution />,
        title: formatMessage,
        description: '',
        duration: 3
      })
    })

    if (newFiles) {
      setFiles((prev) => [...filteredFiles, ...prev])
      uploadFilesSequentially(filteredFiles)
    }
    event.target.value = ''
  }

  return {
    notUploadedFiles,
    totalItems,
    handleInputFileChange
  }
}

export default useStorageFile
