import styled, { css } from 'styled-components'
import theme from '../../styles/theme'
import useFormatText from '../../hooks/useFormatText'
import { tabletMedia } from './constants'
import { memo } from 'react'

type AdInfoCardData = {
  appealPoint: string
  adMessage1: string
  adMessage2: string
  adMessage3: string
}

const AdInfoCard = ({
  index,
  data
}: {
  index: number
  data: AdInfoCardData
}) => {
  const { appealPoint, adMessage1, adMessage2, adMessage3 } = data

  if (!appealPoint && !adMessage1 && !adMessage2 && !adMessage3) return null

  return (
    <div>
      {appealPoint && (
        <div>
          <Point>
            {useFormatText('SIDE_NAV_AI_CONTENT_TAP1_APPEALPOINT')} {index + 1}
          </Point>
          <PointTitle>{appealPoint}</PointTitle>
        </div>
      )}
      <Col>
        {adMessage1 && (
          <TextContainer>
            <strong>01</strong>
            <p>{adMessage1}</p>
          </TextContainer>
        )}
        {adMessage2 && (
          <TextContainer>
            <strong>02</strong>
            <p>{adMessage2}</p>
          </TextContainer>
        )}
        {adMessage3 && (
          <TextContainer>
            <strong>03</strong>
            <p>{adMessage3}</p>
          </TextContainer>
        )}
      </Col>
    </div>
  )
}

export default memo(AdInfoCard)

const Point = styled.div`
  color: ${theme.colors.text['#888']};
  font-size: 12px;
  font-weight: 600;
  line-height: 140%;
`

const PointTitle = styled.div`
  font-weight: 600;
  color: ${theme.colors.text['#222']};
  font-size: 16px;
  word-break: keep-all;
  margin: 8px 0 12px;
`

const Col = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-left: 2px;
  padding-right: 2px;

  ${({ theme }) =>
    theme.breakpoints.medium(css`
      gap: 16px;
      border-radius: 8px;
      padding: 16px;
      background: ${theme.colors.white};
      overflow: hidden;
    `)}
`

const TextContainer = styled.div`
  position: static;
  display: flex;
  align-items: flex-start;
  width: 389px;
  gap: 10px;
  padding: 14px 33px 14px 12px;
  background: ${theme.colors.white};
  border-radius: 8px;

  strong {
    font-weight: 700;
    color: ${theme.colors.text['#aaa']};
    font-size: 14px;
    line-height: 20px;
  }

  p {
    color: ${theme.colors.text['#333']};
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }

  ${tabletMedia(css`
    width: 100%;
  `)};

  ${({ theme }) =>
    theme.breakpoints.medium(css`
      width: 100%;
      border-radius: 0;
      padding: 0;
    `)}
`
