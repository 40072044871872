import styled, { css } from 'styled-components'

import theme from '../../styles/theme'

const SidebarMenu = ({
  span,
  isActive,
  title,
  icons,
  onClick
}: {
  span: number
  isActive: boolean
  title: string
  icons: Icons
  touch?: boolean
  onClick(): void
}) => {
  return (
    <Col span={span}>
      <Wrapper isActive={isActive} icons={icons} onClick={onClick}>
        <MenuIcon type='button' isActive={isActive} icons={icons} />
        <div>{title}</div>
      </Wrapper>
    </Col>
  )
}

export default SidebarMenu

const Col = styled.div<{ span: number }>`
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ theme, span }) =>
    theme.breakpoints.medium(css`
      width: ${Math.floor(100 / span)}%;
    `)}
`

const Wrapper = styled.div<{ isActive: boolean; icons: Icons }>`
  position: relative;
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${({ isActive }) =>
    isActive ? theme.colors.background.active : theme.colors.white};
  z-index: 240;
  transition: all 0.15s;
  cursor: pointer;
  padding: 9px;

  div {
    position: relative;
    color: ${({ isActive }) =>
      isActive ? theme.colors.primary : theme.colors.text['#666']};
    text-align: center;
    line-height: 100%;
    font-size: 10px;
    margin-top: 3px;
  }
`
type Icons = {
  default: string
  hover?: string
  active?: string
  disabled?: string
}
export const MenuIcon = styled.button<{ icons: Icons; isActive: boolean }>`
  width: 24px;
  height: 24px;
  padding: 0;
  margin: 0 9px;
  background: transparent;
  border: none;
  cursor: pointer;
  position: relative;
  background-size: contain;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url('${({ isActive, icons }) =>
    isActive ? icons.active : icons.default}');
`
