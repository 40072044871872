import React, { useEffect, useRef } from 'react'

import Drawer from '../../components/Drawer'
import useBreakpoint from '../../hooks/useBreakpoint'
import { Background, DrawerWrapper, MenuRow, Wrapper } from './_stylesSidebar'
import { useProjectContext } from '../../hooks/useProject'
import { getMenus } from './constants'
import { service } from '../../utils/api'
import { message } from 'antd'
import { useIntl } from 'react-intl'
import SidebarMenu from './SideBarMenu'
import SidebarToggle from './SidebarToggle'
import { useToggleContext } from '../../contexts/ToggleContext'
import { useAiStore } from '../../stores/ai'
import { UsedPoint } from '../../types/project'
import { useUserLimitQuery } from './_queries'

interface SidebarProps {
  isAILoadingFinished: boolean
  setIsModified: React.Dispatch<React.SetStateAction<boolean>>
  setIsAutoLoading: React.Dispatch<React.SetStateAction<boolean>>
  selectedDrawerIndex: number
  setSelectedDrawerIndex: React.Dispatch<React.SetStateAction<number>>
}

const Sidebar = ({
  isAILoadingFinished,
  setIsModified,
  setIsAutoLoading,
  selectedDrawerIndex,
  setSelectedDrawerIndex
}: SidebarProps) => {
  const intl = useIntl()
  const { project, setProject, isMutateLoading, isPlugin } = useProjectContext()
  const { aiInfos, setAiInfos } = useAiStore()
  const [isReset, setIsReset] = React.useState(false)
  const ref = useRef<HTMLDivElement>(null)
  const { isOpen, setIsOpen } = useToggleContext()
  const { isBreakpoint, isMobile } = useBreakpoint()

  const { data: userLimit, refetch } = useUserLimitQuery()

  const handleOnclickAdPoint = async (idx: UsedPoint) => {
    try {
      await service().projects.postAdPoint(idx, project?._id)
      setAiInfos({
        ...aiInfos,
        usedPoint: idx
      })
      await refetch()
      setIsAutoLoading(true)
    } catch {
      setIsAutoLoading(false)
      message.error(
        <>
          <div>{intl.formatMessage({ id: 'AD_POINT_ERROR_MESSAGE1' })}</div>
          <div>{intl.formatMessage({ id: 'AD_POINT_ERROR_MESSAGE2' })}</div>
        </>
      )
    }
  }

  const menu = getMenus({
    intl,
    project,
    selectedDrawerIndex,
    setSelectedDrawerIndex,
    setProject,
    isMutateLoading,
    setIsModified,
    handleOnclickAdPoint,
    aiInfos,
    isReset,
    userLimit,
    setIsReset
  })

  const handleToggleClick = () => {
    if (selectedDrawerIndex !== -1) {
      setSelectedDrawerIndex(-1)
    } else {
      setSelectedDrawerIndex(isPlugin ? 0 : 1)
    }
  }

  useEffect(() => {
    setIsOpen(selectedDrawerIndex !== -1)
  }, [selectedDrawerIndex])

  useEffect(() => {
    if (isAILoadingFinished) {
      setSelectedDrawerIndex(0)
      setIsOpen(true)
    }
  }, [isAILoadingFinished])

  return (
    <Wrapper ref={ref}>
      <Background />
      <MenuRow>
        {!isBreakpoint('xLarge') && (
          <SidebarToggle open={isOpen} handleToggleClick={handleToggleClick} />
        )}
        {menu?.map(({ isActive, icons, title, key, drawer, onClick }) => {
          return (
            <React.Fragment key={key || JSON.stringify(icons)}>
              <SidebarMenu
                span={menu.length}
                isActive={isActive!}
                title={title}
                icons={icons}
                onClick={onClick}
              />
              {drawer && (
                <DrawerWrapper visible={drawer.visible}>
                  {!isMobile && (
                    <SidebarToggle
                      open={isOpen}
                      handleToggleClick={handleToggleClick}
                    />
                  )}
                  <Drawer
                    visible={drawer.visible}
                    closable
                    position={isBreakpoint('xLarge') ? 'bottom' : 'left'}
                  >
                    {drawer.children}
                  </Drawer>
                </DrawerWrapper>
              )}
            </React.Fragment>
          )
        })}
      </MenuRow>
    </Wrapper>
  )
}

export default Sidebar
