import styled, { css } from 'styled-components'
import PanelTitle from '../PanelTitle'
import { StoragePanelProps } from './types'
import theme from '../../styles/theme'
import { useIntl } from 'react-intl'
import useBreakpoint from '../../hooks/useBreakpoint'
import Panel from './Panel'
import Tabs from '../Tabs'
import EmptyText from '../EmptyText'
import Button from '../Button'
import useFormatText from '../../hooks/useFormatText'
import {
  redirectVplateLoginPage,
  redirectVplateSignupPage
} from '../../utils/vplate'
import { useUserInfo } from '../AuthHelper'

const StoragePanel = ({ isActive, handleDrawerClose }: StoragePanelProps) => {
  const intl = useIntl()
  const { isMobile } = useBreakpoint()

  const { userInfo } = useUserInfo()
  const isLogin = !!userInfo?.token?.accessToken

  const handleSignupButtonClick = () => {
    redirectVplateSignupPage()
  }

  const handleLoginButtonClick = () => {
    redirectVplateLoginPage()
  }

  const tabList = [
    {
      title: intl.formatMessage({ id: 'SIDE_NAV_STORAGE' }),
      children: !isLogin ? (
        <div>
          <EmptyText text={useFormatText('LOGIN_REQUIRED')} />
          <ButtonWrapper>
            <Button onClick={handleSignupButtonClick} className='signup-button'>
              {useFormatText('BUTTON_SIGNUP')}
            </Button>
            <Button onClick={handleLoginButtonClick} className='login-button'>
              {useFormatText('BUTTON_SIGNIN')}
            </Button>
          </ButtonWrapper>
        </div>
      ) : (
        <Panel isActive={isActive} />
      )
    }
  ]

  return (
    <Wrapper>
      {isMobile && (
        <PanelTitle
          title='SIDE_NAV_STORAGE'
          handleDrawerClose={handleDrawerClose}
        >
          <TabWrapper isLogin={isLogin}>
            <Tabs tabs={tabList} />
          </TabWrapper>
        </PanelTitle>
      )}
      {!isMobile && (
        <TabWrapper isLogin={isLogin}>
          <Tabs tabs={tabList} />
        </TabWrapper>
      )}
    </Wrapper>
  )
}

export default StoragePanel

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 auto;
`

const TabWrapper = styled.div<{ isLogin: boolean }>`
  width: 100%;
  height: calc(100% - 55px);

  display: flex;
  flex-direction: column;
  gap: 16px;
  color: ${theme.colors.text.secondary};

  ${theme.breakpoints.xLarge(css`
    height: calc(100% - 55px - 146px);
  `)};

  ${({ theme }) =>
    theme.breakpoints.medium(css`
      height: calc(100% - 55px);

      .tab-list-item {
        font-size: 14px;
      }

      .tab-content {
        height: calc(100% - 33px);
      }
    `)};

  ${({ isLogin }) =>
    !isLogin &&
    css`
      .tab-content {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    `};
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 8px;
  margin-top: 16px;

  .signup-button,
  .login-button {
    display: flex;
    width: 134px;
    height: 38px;
    padding: 15px 30px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    flex-shrink: 0;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
  }

  .login-button {
    background-color: ${theme.colors.background.secondary};
    color: ${theme.colors.text['#222']};
    border: none;
  }
`
