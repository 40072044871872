import { useMutation } from 'react-query'
import { service } from '../../utils/api'
import { message } from 'antd'
import { PROJECT_STATUS } from '../../types/constants'
import { useIntl } from 'react-intl'
import { IconCircleCaution } from '../../assets/icons'
import { ToastProps } from '../../components/Toast'

export const useRenderProjectMutation = (
  redirectProject: (options: { isRender: boolean }) => void,
  renderToast: (props: ToastProps) => void,
  onClose: () => void,
  toastTitle: string,
  toastDescription: string
) => {
  const intl = useIntl()
  const { mutate: renderMutate, isLoading } = useMutation<
    Awaited<Promise<boolean>>,
    Error,
    string
  >(
    (projectId: string) =>
      service().projects.changeStatus(projectId, PROJECT_STATUS.RENDER_REQUEST),
    {
      onSuccess: (res) => {
        if (!res) return
        redirectProject({ isRender: true })
      },
      onError: (error) => {
        console.log('error', error)
        onClose()
        if (error instanceof Object && 'msg' in error && 'code' in error) {
          if (error.code === 'WAITING') {
            renderToast({
              icon: <IconCircleCaution />,
              title: toastTitle,
              description: toastDescription,
              duration: 4
            })
            return
          }
          if (typeof error.msg === 'string') {
            message.error(intl.formatMessage({ id: error.msg }))
          }
        }
      }
    }
  )
  return { renderMutate, isLoading }
}
