export const PROJECT_STATUS = {
  WAIT: -1,
  EDIT: 0,
  RENDER_REQUEST: 1,
  RENDERING: 2,
  RENDER_DONE: 3,
  RENDER_CONFIRM: 4
} as const

export const PLAN_TYPE = {
  FREE: [0],
  BASIC: [1, 2, 3, 11, 12, 13],
  ENTERPRISE: [
    101, 102, 103, 104, 105, 106, 107, 108, 109, 111, 112, 113, 114, 115, 116,
    117, 118, 119
  ]
} as const

export type PlanType = (typeof PLAN_TYPE)[keyof typeof PLAN_TYPE][number]
