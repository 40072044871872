import styled, { css } from 'styled-components'
import theme from '../../styles/theme'

import { AdInfo, UsedPoint } from '../../types/project'
import { useIntl } from 'react-intl'
import DefaultButton from '../DefaultButton'
import useBreakpoint from '../../hooks/useBreakpoint'
import EmptyText from '../EmptyText'
import { useAiStore } from '../../stores/ai'
import DefaultTooltip from '../DefaultTootip'
import useFormatText from '../../hooks/useFormatText'
import { ReactComponent as IconInfo } from '../../assets/icons/icon_info_line.svg'
import { ReactComponent as IconMagicStick } from '../../assets/icons/icon-magic_stick_right_black.svg'

interface AIDataListProps {
  adPlanning?: AdInfo[]
  usedPoint?: number
  isValidUserLimit?: boolean
  scenarioLimit?: number
  scenarioCount?: number
  handleClick: (idx: UsedPoint, reqId: string) => void
}

const AIDataList = ({
  adPlanning,
  usedPoint = 1,
  isValidUserLimit,
  scenarioLimit,
  scenarioCount,
  handleClick
}: AIDataListProps) => {
  const { isMobile } = useBreakpoint()

  const intl = useIntl()
  const { aiInfos } = useAiStore()
  const reqId = aiInfos?._id
  const hasAdPlanning = Array.isArray(adPlanning) && adPlanning.length > 0
  const isGPTError = false

  const restScenarioCount = Math.max(
    (scenarioLimit ?? 0) - Math.abs(scenarioCount ?? 0),
    0
  )

  const isExcededLimit = !isValidUserLimit
    ? true
    : typeof scenarioCount === 'number' &&
      typeof scenarioLimit === 'number' &&
      scenarioCount >= scenarioLimit

  const linkToPricing = () => {
    if (!isExcededLimit) return
    window.location.href = `${process.env.REACT_APP_VPLATE_URL}/pricing`
  }

  const handleClickWrapper = (_idx: UsedPoint, _reqId: string) => {
    if (isExcededLimit) return

    handleClick(_idx, _reqId)
  }

  return (
    <>
      {!hasAdPlanning && (
        <EmptyText
          text={intl.formatMessage({
            id: 'SIDE_NAV_AI_CONTENT_NO_SCENARIO'
          })}
        />
      )}
      <Wrapper>
        <RestScenarioWrapper
          isExcededLimit={isExcededLimit}
          onClick={linkToPricing}
        >
          <IconMagicStick className='magic' />
          <p>
            {useFormatText('SIDE_NAV_AI_REST_SCENARIO_COUNT')}
            {!isValidUserLimit && <span style={{ marginLeft: '4px' }}>-</span>}
            {isValidUserLimit && (
              <>
                <strong>{restScenarioCount}</strong>
                <span>/ {scenarioLimit}</span>
              </>
            )}
          </p>
          {isValidUserLimit && (
            <DefaultTooltip
              position={{ top: 'calc(100% + 13px)', right: '-12px' }}
              tail={{
                direction: 'top',
                position: { top: '-8%', right: '16px' }
              }}
              contents={useFormatText(
                'SIDE_NAV_AI_SCENARIO_REDUCE_INFO_TOOLTIP'
              )}
              wrapperStyle={{ backgroundColor: 'transparent' }}
              textStyle={{ fontSize: '14px' }}
              tailStyle={css`
                transform: translateY(-50%);
              `}
              touch
              always={isMobile}
            >
              <IconInfo className='info' />
            </DefaultTooltip>
          )}
        </RestScenarioWrapper>
        {hasAdPlanning &&
          adPlanning.map((group: AdInfo, idx: number) => {
            const { appealPoint, adMessage1, adMessage2, adMessage3 } = group
            return (
              <div key={`text-group-${idx}`}>
                <div>
                  <Point>
                    {intl.formatMessage({
                      id: 'SIDE_NAV_AI_CONTENT_TAP1_APPEALPOINT'
                    })}{' '}
                    {idx + 1}
                  </Point>
                  <Title>{appealPoint}</Title>
                </div>
                <Col>
                  <TextContainer>
                    <strong>01</strong>
                    <p>{adMessage1}</p>
                  </TextContainer>
                  <TextContainer>
                    <strong>02</strong>
                    <p>{adMessage2}</p>
                  </TextContainer>
                  <TextContainer>
                    <strong>03</strong>
                    <p>{adMessage3}</p>
                  </TextContainer>
                </Col>
                <ButtonContainer>
                  {!isGPTError && (
                    <DefaultButton
                      fullWidth={isMobile}
                      category='secondaryMulticolored'
                      onClick={() =>
                        reqId &&
                        handleClickWrapper((idx + 1) as UsedPoint, reqId)
                      }
                      disabled={usedPoint - 1 === idx || isExcededLimit}
                    >
                      {intl.formatMessage({
                        id: 'SIDE_NAV_AI_CONTENT_TAP1_GENERATESCENARIO'
                      })}
                    </DefaultButton>
                  )}
                  {isGPTError && (
                    <DefaultTooltip
                      position={{
                        top: -42,
                        right: 0
                      }}
                      tail={{
                        direction: 'bottom',
                        position: { top: 34, right: 12 }
                      }}
                      contents={useFormatText(
                        'SIDE_NAV_AI_GENERATESCENARIO_ERROR_TOOLTIP'
                      )}
                      touch
                      textStyle={{
                        width: 265,
                        lineHeight: 1.2,
                        whiteSpace: 'pre-wrap'
                      }}
                    >
                      <DefaultButton
                        fullWidth={isMobile}
                        category='secondaryMulticolored'
                        disabled
                      >
                        {intl.formatMessage({
                          id: 'SIDE_NAV_AI_CONTENT_TAP1_GENERATESCENARIO'
                        })}
                      </DefaultButton>
                    </DefaultTooltip>
                  )}
                </ButtonContainer>
              </div>
            )
          })}
      </Wrapper>
    </>
  )
}

export default AIDataList

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 30px;
  line-height: 1.5;
  padding-bottom: 30px;

  * {
    line-height: 100%;
  }

  ${({ theme }) =>
    theme.breakpoints.medium(css`
      padding-bottom: inherit;
    `)}
`

const TextContainer = styled.div`
  position: static;
  display: flex;
  align-items: flex-start;
  gap: 10px;
  padding: 14px;
  margin-bottom: 12px;
  background: ${theme.colors.background.primary};
  border-radius: 8px;

  strong {
    font-weight: 700;
    color: ${theme.colors.text['#aaa']};
    font-size: 14px;
    line-height: 20px;
  }

  p {
    color: ${theme.colors.text['#333']};
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;

    ${({ theme }) =>
      theme.breakpoints.medium(css`
        font-size: 13px;
      `)}
  }

  ${({ theme }) =>
    theme.breakpoints.medium(css`
      margin-bottom: 0px;
    `)}
`

const Point = styled.div`
  color: ${theme.colors.text['#888']};
  font-size: 12px;
  font-weight: 600;
  line-height: 100%;
`

const Title = styled.div`
  font-weight: 600;
  color: ${theme.colors.text['#222']};
  font-size: 16px;
  word-break: keep-all;
  margin: 8px 0 12px;

  ${({ theme }) =>
    theme.breakpoints.medium(css`
      color: ${theme.colors.text['#333']};
      font-size: 15px;
      line-height: normal;
      margin: 4px 0 22px;
    `)}
`

const Col = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  ${({ theme }) =>
    theme.breakpoints.medium(css`
      gap: 8px;
    `)}
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  ${({ theme }) =>
    theme.breakpoints.medium(css`
      margin-top: 12px;
    `)}
`

const RestScenarioWrapper = styled.div<{ isExcededLimit?: boolean }>`
  margin-bottom: -14px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 44px;
  background-color: #f9faff;
  gap: 4px;
  cursor: ${({ isExcededLimit }) => (isExcededLimit ? 'pointer' : 'default')};

  .magic {
    width: 20px;
    height: 20px;
  }

  p {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
    color: #0a0a0a;

    strong {
      margin: 0 2px 0 4px;
      font-weight: 600;
      color: #444bf6;
    }

    span {
      color: #444bf6;
    }
  }

  .info {
    width: 16px;
    height: 16px;
    path {
      fill: #a3a3a3;
    }
  }

  .infinite {
    path {
      stroke: #444bf6;
    }
  }
`
