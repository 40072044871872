import { useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import { IconNotePencil } from '../../assets/icons'
import theme from '../../styles/theme'
import { AdInfo } from '../../types/project'
import { addMissingText, initAdPlanning, tabletMedia } from './constants'
import AiSubtitle from './AiSubtitle'
import useToggle from '../../hooks/useToggle'
import AdInfoCard from './AdInfoCard'
import useAutoScroll from '../../hooks/useAutoScroll'
import LottieIcon from '../../components/Lottie'
import useFormatText from '../../hooks/useFormatText'

const AppealPoints = ({
  progress,
  adPlanning,
  isPendingError,
  isMobile,
  isReset
}: {
  progress?: number
  adPlanning?: AdInfo[]
  isPendingError: boolean
  isMobile: boolean
  isReset: boolean
}) => {
  const [copyAdPlanning, setCopyAdPlanning] = useState(initAdPlanning)
  const timerRef = useRef<NodeJS.Timeout>()
  const scrollRef = useAutoScroll<HTMLDivElement>()
  const isEmpty = !adPlanning?.[0]?.appealPoint
  const adPlanningRef = useRef(adPlanning)
  const isKr = localStorage.getItem('locale')?.includes('ko') ?? false

  const { isToggled, toggle } = useToggle(true)

  const fetchTextList = () => {
    if (!adPlanningRef.current) return
    setCopyAdPlanning((prev) => {
      if (!prev[0]?.appealPoint && adPlanningRef.current) {
        return adPlanningRef.current
      }
      const currentIndex = prev.findIndex((item, idx) => {
        return (
          JSON.stringify(item) !== JSON.stringify(adPlanningRef.current?.[idx])
        )
      })
      if (currentIndex === -1) return prev
      const updatedAdPlanning = prev.map((item, idx) => {
        const updatedItem = { ...item }
        const data = adPlanningRef.current?.[idx]

        if (idx === currentIndex) {
          let hasUpdated = false
          Object.entries(data || {}).forEach(([key, value]) => {
            const updatedValue = updatedItem[key as keyof AdInfo]

            if (!hasUpdated && value.length > updatedValue.length) {
              hasUpdated = true

              updatedItem[key as keyof AdInfo] = addMissingText(
                value,
                updatedValue
              )
            }
          })
          return updatedItem
        }
        return item
      })
      return updatedAdPlanning
    })
  }

  useEffect(() => {
    if (adPlanning && !timerRef.current) {
      timerRef.current = setInterval(fetchTextList, 100)
    }
  }, [adPlanning])

  useEffect(() => {
    if (adPlanning) {
      adPlanningRef.current = adPlanning
    }
  }, [adPlanning])

  useEffect(() => {
    if (
      JSON.stringify(copyAdPlanning) === JSON.stringify(adPlanning) &&
      timerRef.current &&
      progress &&
      progress >= 100
    ) {
      clearInterval(timerRef.current)
    }
  }, [copyAdPlanning, adPlanning, progress])

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current)
      }
    }
  }, [])

  useEffect(() => {
    if (isReset) {
      setCopyAdPlanning(initAdPlanning)
    }
  }, [isReset])

  return (
    <Wrapper>
      <AiSubtitle
        isToggled={isToggled}
        toggle={toggle}
        subtitle={useFormatText('AI_LOADING_MODAL_SUBTITLE1')}
        progressValue={progress || 0}
        progressText='1'
        icon={<IconNotePencil />}
        isShowArrow={isMobile}
      />
      {isToggled && (
        <Container ref={scrollRef} isEmpty={isEmpty} isKr={isKr}>
          {Array.isArray(copyAdPlanning) &&
            copyAdPlanning.length > 0 &&
            !isEmpty &&
            copyAdPlanning.map((group, idx) => {
              return (
                <AdInfoCard
                  key={`text-group-${idx}`}
                  index={idx}
                  data={group}
                />
              )
            })}
          {isEmpty && (
            <div className='empty'>
              <LottieIcon width={34} height={34} />
              {useFormatText(
                isPendingError
                  ? 'AI_LOADING_PLANNING_PENDING'
                  : 'AI_LOADING_PLANNING_PROCESSING'
              )}
            </div>
          )}
        </Container>
      )}
    </Wrapper>
  )
}

export default AppealPoints

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 8px 16px;
  user-select: none;

  ${({ theme }) =>
    theme.breakpoints.medium(
      css`
        padding: 0;
      `
    )};
`

const Container = styled.div<{ isEmpty: boolean; isKr: boolean }>`
  width: 429px;
  height: calc(100% - 36px);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 30px;
  background-color: ${theme.colors.f5f5};
  border-radius: 8px;
  border: 1px solid #ccc;
  padding: 24px 18px;
  margin-top: 12px;

  ${({ isEmpty }) =>
    isEmpty &&
    css`
      color: ${theme.colors.text['#aaa']};
      justify-content: center;
    `};

  .empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
    color: ${theme.colors.text.secondary};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }

  ${tabletMedia(css`
    width: 100%;
  `)};

  ${({ theme }) =>
    theme.breakpoints.medium(
      css`
        width: 100%;
        height: 236px;
        gap: 25px;
        padding: 20px 16px;
      `
    )};
`
