import { useEffect, useRef, useState } from 'react'

const useLoadThumbnail = ({
  fileUrl,
  setIsLoaded
}: {
  fileUrl: string
  setIsLoaded: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  const [thumbnailSrc, setThumbnailSrc] = useState<string | null>(null)
  const timerRef = useRef<NodeJS.Timeout>()
  const retryCount = useRef(0)
  const MAX_RETRY_COUNT = 5
  const isRetryOver = retryCount.current > MAX_RETRY_COUNT

  const loadThumbnail = () => {
    if (retryCount.current > MAX_RETRY_COUNT) {
      setIsLoaded(true)
      clearInterval(timerRef.current)
      return
    }
    const thumbnailUrl = fileUrl.replace('mp4', 'png')
    const img = new Image()

    img.onload = () => {
      setIsLoaded(true)
      setThumbnailSrc(thumbnailUrl)
      clearInterval(timerRef.current)
    }

    img.onerror = () => {
      retryCount.current += 1
    }

    img.src = thumbnailUrl

    timerRef.current = setTimeout(() => {
      loadThumbnail()
    }, 1000)
  }

  useEffect(() => {
    return () => {
      clearInterval(timerRef.current)
    }
  }, [])

  return { thumbnailSrc, loadThumbnail, isRetryOver }
}

export default useLoadThumbnail
