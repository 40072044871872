import styled, { css } from 'styled-components'
import theme from '../../styles/theme'
import { tabletMedia } from './constants'
import { Info } from './_stylesAIMedia'

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 8px 16px;

  ${tabletMedia(css`
    padding: 0;
  `)}

  ${({ theme }) =>
    theme.breakpoints.medium(
      css`
        height: auto;
        padding: 0;
      `
    )};
`

export const InnerWrapper = styled.div<{ isKr: boolean }>`
  width: 100%;
  height: 100%;
  /* height: ${({ isKr }) => !isKr && 'calc(100% - 26px)'}; */
  display: flex;
  flex-direction: column;
  gap: 12px;
  user-select: none;

  ${tabletMedia(css`
    padding: 0;
  `)}

  ${({ theme }) =>
    theme.breakpoints.medium(css`
      height: auto;
      padding: 0;
    `)}
`

export const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  white-space: nowrap;
`

export const ScenarioInfo = styled(Info)`
  padding-left: 16px;
  margin-top: 0;

  ${tabletMedia(css`
    padding-left: 0;
    margin-top: 8px;
  `)}

  ${({ theme }) =>
    theme.breakpoints.medium(css`
      padding-left: 0;
      margin-top: 8px;
    `)}
`

export const TextContainer = styled.div<{
  isEmpty: boolean
  isLoading?: boolean
}>`
  width: 429px;
  height: 100%;
  border-radius: 8px;
  border: 1px solid #ccc;
  padding: 24px 19px 24px;
  background-color: ${theme.colors.f5f5};
  color: ${theme.colors.black};
  font-size: 14px;
  font-weight: 400;
  line-height: 160%;
  white-space: pre-wrap;
  overflow-y: auto;

  ${tabletMedia(css`
    width: 100%;
  `)};

  ${({ isEmpty, isLoading }) =>
    isEmpty &&
    css`
      display: flex;
      justify-content: center;
      align-items: center;
      color: ${theme.colors.text['#aaa']};
      font-size: 14px;
      line-height: 100%;

      ${isLoading &&
      css`
        flex-direction: column;
        gap: 12px;
        text-align: center;
        line-height: 140%;
        white-space: pre-wrap;
      `};
    `};

  ${({ theme }) =>
    theme.breakpoints.medium(css`
      width: 100%;
      height: 236px;
      padding: 20px 16px;
    `)}
`
