import styled, { css } from 'styled-components'
import { ButtonCategory, ButtonProps } from '.'
import theme from '../../styles/theme'

export const primaryCss = css`
  background-color: ${theme.colors.primary};
  color: ${theme.colors.white};

  &:hover {
    background-color: ${theme.colors.button.primary.hoverBg};
  }

  &:disabled {
    background: ${theme.colors.button.primary.disabledBg};
    color: ${theme.colors.button.primary.disabledColor};
  }
`

export const secondaryCss = css`
  &:disabled {
    background: ${theme.colors.button.secondary.disabledBg};
    border: 1px solid ${theme.colors.button.secondary.disabledBorder};
    color: ${theme.colors.button.secondary.disabledColor};

    &:hover {
      background: ${theme.colors.button.secondary.disabledBg};
      border: 1px solid ${theme.colors.button.secondary.disabledBorder};
      color: ${theme.colors.button.secondary.disabledColor};
    }
  }
`

export const secondaryMonochromeCss = css`
  ${secondaryCss}
  border: 1px solid ${theme.colors.button.secondary.monochrome.border};
  color: ${theme.colors.button.secondary.monochrome.color};

  &:hover {
    border: 1px solid ${theme.colors.button.secondary.monochrome.hoverBorder};
    color: ${theme.colors.button.secondary.monochrome.hoverColor};
  }
`

export const secondaryMulticoloredCss = css`
  ${secondaryCss}
  border: 1px solid ${theme.colors.primary};
  color: ${theme.colors.primary};

  &:hover {
    border: 1px solid ${theme.colors.button.secondary.multicolored.hoverBorder};
    color: ${theme.colors.button.secondary.multicolored.hoverColor};
  }
`

const compactCss = css`
  height: 32px;
  padding: 0 12px;
  border-radius: 24px;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
`

export const compactMonochromeCss = css`
  ${compactCss}
  color: ${theme.colors.button.compact.monochrome.color};

  &:hover {
    color: ${theme.colors.button.compact.monochrome.hoverColor};
    border: 1px solid ${theme.colors.button.compact.monochrome.hoverBorder};
  }

  &:disabled {
    background: ${theme.colors.button.compact.monochrome.disabledBg};
    color: ${theme.colors.button.compact.monochrome.disabledColor};

    &:hover {
      background: ${theme.colors.button.compact.monochrome.disabledBg};
      color: ${theme.colors.button.compact.monochrome.disabledColor};
    }
  }
`

export const compactMulticoloredCss = css`
  ${compactCss}
  background: -webkit-linear-gradient(
    45deg,
    ${theme.colors.primary},
    ${theme.colors.secondary}
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: 24px;
    padding: 1px;
    background: linear-gradient(
      90deg,
      ${theme.colors.primary},
      ${theme.colors.secondary}
    );
    -webkit-mask: linear-gradient(${theme.colors.white} 0 0) content-box,
      linear-gradient(${theme.colors.white} 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }

  &:disabled {
    background: -webkit-linear-gradient(
      45deg,
      ${theme.colors.button.compact.multicolored.disabledColor},
      ${theme.colors.button.compact.multicolored.disabledColor}
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    &::before {
      background: linear-gradient(
        90deg,
        ${theme.colors.button.compact.multicolored.disabledBorder},
        ${theme.colors.button.compact.multicolored.disabledBorder}
      );
      -webkit-mask: linear-gradient(${theme.colors.white} 0 0) content-box,
        linear-gradient(${theme.colors.white} 0 0);
    }

    &:hover {
      background: -webkit-linear-gradient(
        45deg,
        ${theme.colors.button.compact.multicolored.disabledColor},
        ${theme.colors.button.compact.multicolored.disabledColor}
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
`

export const compactTtansparentCss = css`
  ${compactCss}
  font-size: 14px;
  color: ${theme.colors.button.compact.monochrome.color};
  padding: 6px;

  &:hover {
    color: ${theme.colors.button.compact.monochrome.hoverColor};
    border: none;
  }

  &:disabled {
    background: none;
    color: ${theme.colors.button.compact.monochrome.disabledColor};

    &:hover {
      background: none;
      color: ${theme.colors.button.compact.monochrome.disabledColor};
    }
  }
`

export const largeCss = css`
  min-width: 206px;
  height: 44px;
  border-radius: 4px;
  background: ${theme.colors.gradient};
  color: ${theme.colors.white};
  font-size: 16px;

  &:disabled {
    background: ${theme.colors.button.large.disabledBg};
  }
`

const getCategoryCss = (category: ButtonCategory) => {
  switch (category) {
    case 'primary':
      return primaryCss
    case 'secondaryMonochrome':
      return secondaryMonochromeCss
    case 'secondaryMulticolored':
      return secondaryMulticoloredCss
    case 'compactMonochrome':
      return compactMonochromeCss
    case 'compactMulticolored':
      return compactMulticoloredCss
    case 'compactTransparent':
      return compactTtansparentCss
    case 'large':
      return largeCss
    default:
      return compactMulticoloredCss
  }
}

export const ButtonCompo = styled.button<ButtonProps>`
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 38px;
  padding: 0 20px;
  font-size: 14px;
  font-weight: 600;
  line-height: 100%;
  border: none;
  border-radius: 4px;
  background: transparent;
  cursor: pointer;
  transition: all 0.1s ease;

  &:disabled {
    cursor: auto;
  }

  ${({ category }) => getCategoryCss(category)};

  ${({ fullWidth }) => fullWidth && 'width: 100%;'};

  ${({ loading }) => loading && `pointer-events: none;`};
`

export const LottieWrapper = styled.div`
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ChildrenWrapper = styled.div<{ loading: boolean }>`
  display: inline-flex;
  align-items: center;
  opacity: ${({ loading }) => (loading ? 0 : 1)};
`
