import { create } from 'zustand'
import { AIInfos } from '../types/project'

type AiInfosStore = {
  aiInfos: AIInfos
  setAiInfos: (aiInfos: AIInfos) => void
}

export const useAiStore = create<AiInfosStore>((set) => ({
  aiInfos: {
    adPlanning: [],
    images: [],
    videos: [],
    usedPoint: 1,
    scenario: null,
    status: null,
    _id: '',
    requestType: null
  },
  setAiInfos: (aiInfos: AIInfos) => set({ aiInfos })
}))
