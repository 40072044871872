import { useParams } from 'react-router-dom'
import { ProjectContext, useProject } from '../hooks/useProject'

import Main from './Main'
import AuthHelper from '../components/AuthHelper'
import { useAiStore } from '../stores/ai'

const Editor = () => {
  const { projectId } = useParams<{ projectId: string }>()
  const { project, setProject, sceneList, refetchProject } =
    useProject(projectId)

  const { needCheck, renderCnt, renderLimit } = project ?? {}
  const isLimit =
    needCheck && renderCnt !== undefined && renderLimit !== undefined
      ? renderCnt >= renderLimit
      : false

  const { aiInfos } = useAiStore()
  const isPlugin = (aiInfos?.adPlanning || [])?.length > 0

  return (
    <ProjectContext.Provider
      value={{
        project,
        setProject,
        sceneList,
        projectId,
        refetchProject,
        isLimit,
        isPlugin
      }}
    >
      <AuthHelper projectId={projectId}>
        <Main projectId={projectId} status={project?.status} />
      </AuthHelper>
    </ProjectContext.Provider>
  )
}

export default Editor
