import { css } from 'styled-components'
import { sizes } from '../../styles/theme/breakpoints'

export const dummyItem = {
  images: [],
  adPlanning: [
    {
      appealPoint: 'Water Resistance',
      adMessage1: 'Step into any situation without fear of getting wet.',
      adMessage2: 'Our Nike Aqua shoes provide superior water resistance.',
      adMessage3:
        'Stay dry and comfortable in all conditions with Nike Aqua shoes.'
    },
    {
      appealPoint: 'Stylish Design',
      adMessage1: "Stand out with the world's most beautiful aqua shoes.",
      adMessage2:
        'Nike Aqua shoes blend functionality with exceptional design.',
      adMessage3:
        'Make a fashion statement even in wet conditions with Nike Aqua shoes.'
    },
    {
      appealPoint: 'Brand Trust',
      adMessage1:
        'Trust in the quality and durability that comes with the Nike brand.',
      adMessage2: 'Nike, a brand synonymous with excellence in footwear.',
      adMessage3:
        'Choose Nike Aqua shoes for a reliable and stylish footwear solution.'
    }
  ],
  usedPoint: 1,
  reqId: '64c1d57888942b0cece9ee69',
  scenario: {
    appealPoint1: {
      text: [
        'Looking for water-resistant shoes?',
        'Nike Aqua shoes',
        'Embrace the freedom of water',
        'No more soggy feet',
        '%',
        'OFF',
        'Special benefits of Nike Aqua shoes',
        'Available now!'
      ],
      image: []
    },
    appealPoint2: {
      text: [
        'Want stylish and functional shoes?',
        'Nike Aqua shoes',
        'Stand out with our stylish design',
        'Make a fashion statement',
        '%',
        'OFF',
        'Unique features of Nike Aqua shoes',
        'Get yours today!'
      ],
      image: []
    },
    appealPoint3: {
      text: [
        'Looking for a reliable brand?',
        'Nike Aqua shoes',
        'Experience the Nike brand promise',
        'Quality in every step',
        '%',
        'OFF',
        "Trust in Nike's excellence",
        "Don't miss out!"
      ],
      image: []
    }
  }
}

export const dummyImages = Array(11)
  .fill(0)
  .map((_, i) => ({
    type: 'image',
    url: `https://picsum.photos/200/300?random=${i}`
  }))

export const dummyVideos = [
  {
    type: 'video',
    url: 'https://player.vimeo.com/external/199944928.sd.mp4?s=9110fea688984a285fe5cf6a8ab86bf9db2d3b58&profile_id=164&oauth2_token_id=57447761',
    duration: 15
  },
  {
    type: 'video',
    url: 'https://player.vimeo.com/external/368064896.sd.mp4?s=62ea7eba7cbffc3d0d10da8630b3635585979411&profile_id=139&oauth2_token_id=57447761',
    duration: 85
  },
  {
    type: 'video',
    url: 'https://player.vimeo.com/external/482693514.hd.mp4?s=1dcdb9ec1cc5435891ba03f2c08b57d70853a317&profile_id=172&oauth2_token_id=57447761',
    duration: 12
  },
  {
    type: 'video',
    url: 'https://player.vimeo.com/external/200785110.hd.mp4?s=4317c2c4bb6b25c1f86e2dfc66227a16594753c2&profile_id=174&oauth2_token_id=57447761',
    duration: 10
  },
  {
    type: 'video',
    url: 'https://player.vimeo.com/external/205484487.sd.mp4?s=aebde4814c3627944321d5abdc95e94604112d78&profile_id=165&oauth2_token_id=57447761',
    duration: 30
  }
]

const shuffleArray = (array: any[]) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    ;[array[i], array[j]] = [array[j], array[i]]
  }
  return array
}

export const dummyMedias = shuffleArray([...dummyImages, ...dummyVideos])

export const addMissingText = (newText: string, prevText: string) => {
  const newWords = [...newText].filter((_word, index) => !prevText[index])
  if (newWords.length === 0) return prevText
  return prevText + newWords[0]
}

export const twoDigits = (_num: number) => {
  // eslint-disable-next-line no-extra-boolean-cast
  const num = !!_num ? _num : 0
  return num < 10 ? '0' + num : num
}

export const TABLET_SIZE = 1480
export const tabletMedia = (args: ReturnType<typeof css>) => {
  return css`
    @media only screen and (min-width: ${sizes.medium}px) and (max-width: ${TABLET_SIZE}px) {
      ${args}
    }
  `
}

export const getTitle = (isTablet: boolean, isError: boolean) => {
  if (isError) {
    return 'AI_LOADING_MODAL_DESCRIPTION3'
  }
  if (isTablet) {
    return 'AI_LOADING_MODAL_DESCRIPTION2'
  }
  return 'AI_LOADING_MODAL_DESCRIPTION1'
}

export const initAdPlanning = [
  {
    appealPoint: '',
    adMessage1: '',
    adMessage2: '',
    adMessage3: ''
  },
  {
    appealPoint: '',
    adMessage1: '',
    adMessage2: '',
    adMessage3: ''
  },
  {
    appealPoint: '',
    adMessage1: '',
    adMessage2: '',
    adMessage3: ''
  }
]

export const getTooltipStyle = (isTablet: boolean, isLimit?: boolean): any => {
  const common = {
    contentStyle: {
      width: isLimit ? 180 : 246,
      padding: '6px 8px'
    },
    textStyle: { fontSize: 12, letterSpacing: '-0.24px', whiteSpace: 'initial' }
  }

  if (isTablet) {
    return {
      ...common,
      position: { top: -4, right: 'calc(100% + 12px)' },
      tail: { direction: 'right', position: { top: 18, right: -5 } }
    }
  }
  return {
    ...common,
    position: { top: -4, left: 'calc(100% + 12px)' },
    tail: { direction: 'left', position: { top: 18, left: -5 } }
  }
}
