/* eslint-disable react/no-unescaped-entities */
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { InfiniteData } from 'react-query'
import { IconBlank, IconSearch } from '../../assets/icons'
import AspectRatioSizer from '../../components/AspectRatioSizer'
// import PhotoFilter from '../../components/Dropdown/PhotoFilter'
import InputElement from '../../components/Input'
import Tabs from '../../components/Tabs'
import {
  PhotoData,
  ResultPhotoProps,
  ResultVideoProps
} from '../../utils/api/images'
import {
  BlankWrapper,
  Content,
  ImageList,
  ImageListItem,
  InputWrapper,
  LottieWrapper,
  ObserveElement,
  SearchForm
} from './_stylesModal'
// import AIImageList from '../../components/AIPanel/AIImageList'
import { useIntl } from 'react-intl'
import { css } from 'styled-components'
import Storage from './Storage'
// import { useAiStore } from '../../stores/ai'
import checkXSS from '../../utils/libs/checkXSS'
import { ErrorMessage } from './TextModal'
import Button from '../../components/Button'

interface PhotoModalTabsProps {
  query: string
  handleSearch: (e: React.FormEvent<HTMLFormElement>) => void
  showDropbox: boolean
  setOrientation: Dispatch<SetStateAction<string>>
  imageList:
    | InfiniteData<ResultPhotoProps | ResultVideoProps | undefined>
    | undefined
  isFetching: boolean
  listLoad: () => React.ReactNode
  fetchNextPage: () => void
  selectedImageUrl: string
  setSelectedImageUrl: Dispatch<SetStateAction<string>>
  previewLoadStart: () => void
  previewLoading: boolean
  setSelectedAssetInfo: Dispatch<SetStateAction<any>>
  selectedTabIndex: number
  setSelectedTabIndex: Dispatch<SetStateAction<number>>
}

const PhotoModalTabs = (props: PhotoModalTabsProps) => {
  const {
    query,
    handleSearch,
    // showDropbox,
    // setOrientation,
    imageList,
    isFetching,
    listLoad,
    fetchNextPage,
    selectedImageUrl,
    setSelectedImageUrl,
    previewLoadStart,
    previewLoading,
    setSelectedAssetInfo,
    selectedTabIndex,
    setSelectedTabIndex
  } = props

  const intl = useIntl()
  const { formatMessage } = intl
  // const { aiInfos } = useAiStore()
  // const isPlugin = (aiInfos?.adPlanning || [])?.length > 0
  const [inputValue, setInputValue] = useState(query)
  const [isWarning, setIsWarning] = useState(false)

  // const { uploadMutate } = useUploadImageQuery(setSelectedImageUrl)

  const hasResult = imageList?.pages[0] && imageList.pages[0]?.total > 0
  const { ref: observeRef, inView } = useInView({
    threshold: 0,
    triggerOnce: true
  })

  useEffect(() => {
    inView && fetchNextPage()
  }, [fetchNextPage, inView])

  const onClickImage = (img: PhotoData | string) => {
    const image = typeof img === 'string' ? img : img?.image
    const str = typeof img === 'string' ? '' : '?w=1280'
    const url = `${image}${str}`
    if (selectedImageUrl === url) return
    setSelectedImageUrl(url)
    previewLoadStart()
  }

  const handleChangeQuery = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value

    const result = checkXSS(newValue)

    if (result.length !== newValue.length) {
      setIsWarning(true)
      return
    }
    setIsWarning(false)
    setInputValue(newValue)
  }

  const list =
    imageList &&
    imageList.pages.map((page) => {
      return page?.data.map((d) => {
        const image = d as PhotoData
        return (
          <ImageListItem
            key={image.id}
            src={image.thumbnail}
            selected={image?.image === selectedImageUrl}
            onClick={() => {
              onClickImage(image)
              setSelectedAssetInfo(null)
            }}
            isPreviewLoading={previewLoading}
          >
            <AspectRatioSizer aspect={16 / 9} />
          </ImageListItem>
        )
      })
    })

  const getTabs = () => {
    // const autoTab = {
    //   title: formatMessage({ id: 'IMAGE_INPUT_FROM_AI' }),
    //   children: (
    //     <AIImageList
    //       aiImages={aiInfos.images}
    //       scenario={aiInfos?.scenario}
    //       rowStyle={{
    //         height: '50vh',
    //         overflowY: 'auto'
    //       }}
    //       onClickImage={(image) => {
    //         onClickImage(image)
    //         setSelectedAssetInfo(null)
    //       }}
    //       usedPoint={aiInfos?.usedPoint}
    //       emptyTextStyle={{ marginTop: '70px' }}
    //     />
    //   ),
    //   onClick: () => setSelectedTabIndex(1)
    // }

    const freeTab = {
      title: formatMessage({ id: 'IMAGE_INPUT_FROM_FREESTOCK' }),
      children: (
        <div
          style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            position: 'relative'
          }}
        >
          <SearchForm
            innerHeight={document.documentElement.clientHeight}
            onSubmit={handleSearch}
          >
            <InputWrapper
              style={{
                marginBottom: isWarning ? 7 : 24
              }}
            >
              <InputElement
                suffixElement={
                  <Button link type='submit' style={{ padding: 0 }}>
                    <IconSearch />
                  </Button>
                }
                name='query'
                value={inputValue}
                onChange={handleChangeQuery}
                placeholder={formatMessage({
                  id: 'IMAGE_INPUT_FROM_FREESTOCK_SEARCH_PLACEHOLDER'
                })}
                block
                type='search'
                inputMode='search'
                autoComplete='off'
                style={{
                  zIndex: 200
                }}
              />
              {/* {showDropbox && <PhotoFilter setOrientaion={setOrientation} />} */}
              {isWarning && (
                <ErrorMessage onAnimationEnd={() => setIsWarning(false)}>
                  ' & _ () 를 제외한 특수문자는 입력하실 수 없습니다.
                </ErrorMessage>
              )}
            </InputWrapper>
          </SearchForm>
          <Content>
            <div
              style={{
                height: '100%',
                overflowY: 'auto'
              }}
            >
              {hasResult ? (
                <ImageList>{list}</ImageList>
              ) : (
                <>
                  {!isFetching && (
                    <BlankWrapper>
                      <IconBlank />
                      <span>{formatMessage({ id: 'NO_SEARCH_RESULT' })}</span>
                    </BlankWrapper>
                  )}
                </>
              )}
              {isFetching ? (
                <LottieWrapper>{listLoad()}</LottieWrapper>
              ) : (
                <>{hasResult && <ObserveElement ref={observeRef} />}</>
              )}
            </div>
          </Content>
        </div>
      ),
      onClick: () => setSelectedTabIndex(0)
    }

    const assetTab = {
      title: formatMessage({ id: 'IMAGE_INPUT_FROM_STORAGE' }),
      children: (
        <Storage
          onClickItem={(asset: any) => {
            if (!asset) return
            onClickImage(asset?.convertedUrl || asset?.originalUrl)
            setSelectedAssetInfo(asset)
          }}
          type='image'
        />
      ),
      onClick: () => setSelectedTabIndex(1)
    }

    // if (isPlugin) {
    //   return [freeTab, autoTab, assetTab]
    // }
    return [freeTab, assetTab]
  }

  return (
    <Tabs
      tabs={getTabs()}
      selectedTabIndex={selectedTabIndex}
      wrapperStyle={wrapperStyle}
    />
  )
}

export default PhotoModalTabs

const wrapperStyle = css`
  .tab-content {
    height: calc(100% - 52px);
    padding-left: 0;
    padding-right: 0;
    overflow: hidden;

    ${({ theme }) => theme.breakpoints.medium`
        height: calc(100% - 35px);
        display: flex;
        flex-direction: column;
        overflow-x: hidden;
        overflow-y: auto;
    `};
  }

  .tab-list-item {
    &:first-of-type {
      margin-left: 0;
    }
  }
`
